import moment from "moment/moment";
import React from "react";

export default function Comment(props: any) {
    return (
        <li key={props.comment.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
            <div
                className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">Commented
                            by: {props.comment.user.name}</h3>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">Date
                        Shared: {moment(props.comment.created_at).format('dddd, MMMM D, YYYY')}</p>
                    <p className="mt-1 truncate text-sm text-gray-500">Time
                        Shared: {moment(props.comment.created_at).format('hh:mm A')}</p>
                    <div className="mt-3 p-3 w-full font-semibold">
                        <p className="text-xs text-gray-500">
                            {props.comment.comment}
                        </p>
                    </div>
                </div>
            </div>
        </li>
    )
}