import {useController, UseControllerProps} from "react-hook-form";
import {Listbox as ListBox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";

type publishOption = {
    id: number;
    title: string;
    description: string;
};

type Props = {
    publishingOptions: publishOption[];
};

interface IFormInputs {
    growth_opportunity: number
    competency: number
    goal: string
    goal_time: string
    competency_vision: string
    competency_subject: string
    competency_support: string
    actions: string
    actions_support: string
    debrief: string
    exchange_submission: [] | null
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export const Listbox = (props: Props & UseControllerProps<IFormInputs>) => {
    const {field: {value, onChange}} = useController(props);

    const {publishingOptions} = props;

    let selectedValue = publishingOptions.filter((item) => {
        return item.id === value
    })

    return (
        <ListBox value={value} onChange={onChange}>
            <ListBox.Label className="sr-only"> Change published status </ListBox.Label>

            <ListBox.Button
                className="w-full inline-flex items-center rounded-md bg-green-600 p-2 text-sm font-medium text-white hover:bg-green-700 ">
                <div className="inline-flex items-center rounded-l-md text-white block w-full">
                    <p className="ml-2.5 text-sm font-medium">{selectedValue[0]?.title ?? "- Select a value -"}</p>
                </div>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </ListBox.Button>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <ListBox.Options
                    className="relative mt-2 w-fit origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {publishingOptions.map((option) => (
                        <ListBox.Option
                            key={option.id}
                            className={({ active }) =>
                                classNames(
                                    active ? 'text-black bg-slate-100' : 'text-gray-900',
                                    'cursor-pointer select-none p-4 text-sm block w-full'
                                )
                            }
                            value={option.id}
                        >
                            {({ selected, active }) => (
                                <div className="flex flex-col block w-full">
                                    <div className="flex justify-between block w-full">
                                        <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                        {selected ? (
                                            <span className={active ? 'text-black' : 'text-indigo-500'}>
                                                <CheckIcon className={`h-5 w-5 ${selected ? 'text-green-600' : ''}`} aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </div>
                                    <p className={classNames(active ? 'text-black' : 'text-gray-500', 'mt-2')}>
                                        {option.description}
                                    </p>
                                </div>
                            )}
                        </ListBox.Option>
                    ))}
                </ListBox.Options>
            </Transition>
        </ListBox>
    );
};