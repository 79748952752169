import React, {useEffect, useState} from 'react';
import Exchange_submissionService from "../../Services/exchange_submission.service";
import moment from "moment";
import DataTable, {TableColumn} from "react-data-table-component";
import {Link} from "react-router-dom";

interface DataRow {
    exchange: {
        name: string
    };
    user: {
        name: string
    },
    updated_at: string
    date_shared: string
}

const columns: TableColumn<DataRow>[] = [
    {
        name: 'Exchange Type',
        selector: row => row.exchange.name,
        format: row => formatExchangeName(row),
        width: "200px",
    },
    {
        name: 'Preview',
        selector: row => row.updated_at,
        format: row => formatPreview(row),
        width: "500px"
    },
    {
        name: 'Modified By',
        selector: row => row.user.name,
        width: "200px"
    },
    {
        name: 'Last Modification',
        selector: row => row.updated_at,
        width: "200px",
        format: row =>
            <span>
                {moment(row.updated_at).format('MMM D, YYYY')}
            </span>
    },
    {
        name: 'Date Shared',
        selector: row => row.date_shared,
        format: row =>
            <span>
                {moment(row.date_shared).format('MMM D, YYYY')}
            </span>
    },
    {
        name: '',
        selector: row => row.user.name,
        format: row => formatActionType(row),
        width: "100px"
    },
];

function formatExchangeName(row: any) {
    if (row.exchange_id === 1) {
        return "Conversation"
    }

    if (row.exchange_id === 2) {
        return 'Goal and Experience'
    }

    if (row.exchange_id === 3) {
        return 'Review'
    }
}

function formatPreview(row: any) {
    if (row.exchange_id === 1) {
        return <div className="line-clamp-2 text-gray-400 ... overflow-hidden ...">
            <p className="text-sm whitespace-normal">
                {row.conversation_exchanges[0].successes}
            </p>
        </div>
    }

    if (row.exchange_id === 2) {
        return <div className="line-clamp-2 text-gray-400 ... overflow-hidden ...">
            <p className="text-sm whitespace-normal">
                { row.goal_and_experience_exchanges[0].goal }
            </p>
        </div>
    }

    if (row.exchange_id === 3) {
        return <div className="line-clamp-2 text-gray-400 ... overflow-hidden ...">
            <p className="text-sm whitespace-normal">
                {row.review_exchanges[0].teamwork}
            </p>
        </div>
    }
}

function formatActionType(row: any) {
    if (row.exchange_id === 1) {
        return <h2>
            <Link to={'/conversation-exchange/' + row.conversation_exchanges[0]?.unique_id}
                  className={'text-blue-600 hover:text-blue-900 hover:underline'}>
                View
            </Link>
        </h2>
    }

    if (row.exchange_id === 2) {
        return <h2>
            <Link to={'/goal-and-experience-exchange/' + row.goal_and_experience_exchanges[0]?.unique_id}
              className={'text-blue-600 hover:text-blue-900 hover:underline'}>
            View
            </Link>
        </h2>
    }

    if (row.exchange_id === 3) {
        return <h2>
            <Link to={'/reviews-exchange/' + row.review_exchanges[0]?.unique_id}
              className={'text-blue-600 hover:text-blue-900 hover:underline'}>
                View
            </Link>
        </h2>
    }
}

export default function TableShared() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const handlePageChange = (page: any) => {
        retrieveExchangeSubmissions(page);
    };

    useEffect(() => {
        retrieveExchangeSubmissions(1);
    }, []);

    const retrieveExchangeSubmissions = async (page: number) => {
        Exchange_submissionService.get(`?filter[is_draft]=0&filter[exchange_id]=1,2,3&include=exchange,user,goalAndExperienceExchanges,reviewExchanges,conversationExchanges&sort=-id&page=${page}&per_page=${perPage}&delay=1`)
            .then((response: any) => {
                setData(response.data.data);
                setTotalRows(response.data.total);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        const response = await Exchange_submissionService.get(`?filter[is_draft]=0&filter[exchange_id]=1,2,3&include=exchange,user,goalAndExperienceExchanges,reviewExchanges,conversationExchanges&sort=-id&page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const customStyles = {
        table: {
            style: {
                border: "1px solid #e5e7eb",
                borderRadius: '5px',
            },
        },
        tableWrapper: {
            style: {
                borderRadius: '5px',
            },
        },
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
                backgroundColor: "rgb(249 250 251)",
                borderRadius: '5px',
            },
        },
    };

    return (
        <div className={'p-8 min-w-full divide-y divide-gray-300'}>
            <div className="sm:flex-auto pb-6">
                <h1 className="text-xl font-semibold text-gray-900">Shared</h1>
            </div>

            <DataTable
                title=""
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    )
}