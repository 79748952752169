import React, {Fragment, useState} from "react"
import {Combobox, Transition} from "@headlessui/react"
import {CheckIcon} from "@heroicons/react/20/solid"
import {useController, UseControllerProps} from "react-hook-form"

export type Competency = {
    id: number
    name: string
}

const competencies: Array<Competency> = [
    {id: 1, name: 'Action Oriented'},
    {id: 2, name: 'Attracts Top Talent'},
    {id: 3, name: 'Balances Stakeholders'},
    {id: 4, name: 'Being Resilient'},
    {id: 5, name: 'Builds Effective Teams'},
    {id: 6, name: 'Builds Networks'},
    {id: 7, name: 'Business Insight'},
    {id: 8, name: 'Collaborates'},
    {id: 9, name: 'Communicates Effectively'},
    {id: 10, name: 'Courage'},
    {id: 11, name: 'Cultivates Innovation'},
    {id: 12, name: 'Customer Focus'},
    {id: 13, name: 'Decision Quality'},
    {id: 14, name: 'Demonstrates Self-Awareness'},
    {id: 15, name: 'Develops Talent'},
    {id: 16, name: 'Directs Work'},
    {id: 17, name: 'Drives Engagement'},
    {id: 18, name: 'Drives Results'},
    {id: 19, name: 'Drives Vision and Purpose'},
    {id: 20, name: 'Ensures Accountability'},
    {id: 21, name: 'Financial Acumen'},
    {id: 22, name: 'Global Perspective'},
    {id: 23, name: 'Instills Trust'},
    {id: 24, name: 'Interpersonal Savvy'},
    {id: 25, name: 'Manages Ambiguity'},
    {id: 26, name: 'Manages Complexity'},
    {id: 27, name: 'Manages Conflict'},
    {id: 28, name: 'Nimble Learning'},
    {id: 29, name: 'Optimises Work Processes'},
    {id: 30, name: 'Organizational Savvy'},
    {id: 31, name: 'Persuades'},
    {id: 32, name: 'Plans and Aligns'},
    {id: 33, name: 'Resourcefulness'},
    {id: 34, name: 'Self-Development'},
    {id: 35, name: 'Situational Adaptability'},
    {id: 36, name: 'Strategic Mindset'},
    {id: 37, name: 'Tech Savvy'},
    {id: 38, name: 'Values Differences'},
]

interface IFormInputs {
    growth_opportunity: number
    competency: number
    goal: string
    goal_time: string
    competency_vision: string
    competency_subject: string
    competency_support: string
    actions: string
    actions_support: string
    debrief: string
    exchange_submission: [] | null
}

export const ComboBox = (props: any & UseControllerProps<IFormInputs>) => {
    const [query, setQuery] = useState("")
    const {field: {value, onChange}} = useController(props);

    const filteredContacts =
        query === ""
            ? competencies
            : competencies.filter((contact) =>
                contact.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(query.toLowerCase().replace(/\s+/g, ""))
            )

    let selectedValue = competencies.filter((item) => {
        return item.id === value
    })

    return (
        <Combobox
            value={value}
            onChange={onChange}
            refName={props.name}
        >
            <>
                <div className="relative mt-1 flex-1">
                    <div
                        className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(compency: Competency) => selectedValue[0]?.name}
                        />

                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">

                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}
                    >
                        <Combobox.Options
                            className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredContacts.length === 0 && query !== "" ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredContacts.map((contact) => (
                                    <Combobox.Option
                                        key={contact.id}
                                        className={({active}) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? "bg-teal-600 text-white" : "text-gray-900"
                                            }`
                                        }
                                        value={contact.id}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <span
                                                    className={`block truncate ${
                                                        selected ? "font-extrabold" : "font-normal"
                                                    }`}
                                                >
                                                  {contact.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            active ? "text-white" : "text-teal-600"
                                                        }`}
                                                    >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                  </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </>
        </Combobox>
    )
}