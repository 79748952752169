import React, {useState} from "react";
import {useDraggable} from "@dnd-kit/core";

export default function Card(props: any) {
    const [toggleOpen, setToggleOpen] = useState(props.initial_open ?? true);
    const [flipped, setFlipped] = useState(false);

    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
        data: {
            card_id: props.id,
        },
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    function handleToggleOpen() {
        setToggleOpen(!toggleOpen);
    }

    function flipCard() {
        setFlipped(!flipped);
    }

    let skilledItems: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[] = [];
    let overusedItems: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[] = [];
    let lessSkilledItems: (string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined)[] = [];

    if (props.skilled_description != null && props.skilled_description.length > 0) {
        skilledItems = props.skilled_description.split("|") ?? "";
        overusedItems = props.overused_description.split("|") ?? "";
        lessSkilledItems = props.less_skilled_description.split("|") ?? "";
    }

    return (
        <div className="overflow-hidden rounded-lg bg-white shadow border border-zinc-200 mt-2" ref={setNodeRef}
             style={style} {...listeners} {...attributes}>
            <div className="px-4 py-3 sm:px-6 bg-gray-50 rounded-tl-[10px] rounded-tr-[10px] border-b border-zinc-200">
                <div className="flex justify-between items-center">
                    <div className="flex gap-4 items-center">
                        <div className="text-blue-950 text-sm font-normal">{props.id}</div>
                        <div className="w-px h-[30px] bg-zinc-200"/>

                        <div className={'text-left'}>
                            <div className="left-0 top-0 text-blue-950 text-sm font-semibold">{props.name}
                            </div>
                            <div className="h-3 left-0 top-[21px] justify-start items-start gap-1.5 inline-flex">
                                <div className="text-slate-500 text-[10px] font-normal uppercase tracking-tight">
                                    {props.primary_text}
                                </div>
                                <div className="text-slate-500 text-[10px] font-normal uppercase tracking-tight">·</div>
                                <div className="text-slate-500 text-[10px] font-normal uppercase tracking-tight">
                                    {props.secondary_text}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-slate-500 text-[10px] font-normal uppercase tracking-tight">
                        {!toggleOpen &&
                            <svg onClick={() => handleToggleOpen()} xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
                            </svg>
                        }
                        {toggleOpen &&
                            <svg onClick={() => handleToggleOpen()} xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                            </svg>
                        }
                    </div>
                </div>
            </div>
            <div className={toggleOpen ? 'hidden' : null + " px-4 py-5 sm:p-6"}>
                <div className="text-blue-950 text-sm font-medium leading-tight text-left">
                    {!flipped &&
                        <div className="wrapperflip">
                            <div>
                                {props.description}
                            </div>
                            <br/>
                            <div className="flex gap-3 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     style={{color: "white", background: 'green', borderRadius: '50px'}} fill="none"
                                     viewBox="0 0 24 24" strokeWidth="1.5"
                                     stroke="currentColor" className="w-3 h-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"/>
                                </svg>
                                <p className="text-xs font-semibold uppercase tracking-[3.60px]">SKILLED</p>
                            </div>
                            <br/>


                            <div className="text-blue-950 text-[13px] font-normal leading-[18.20px] ml-9">
                                <ul className="list-disc">
                                    {skilledItems.length > 0 && skilledItems.map((item, index) => (
                                        <li key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }
                    {flipped &&
                        <div className="wrapperflip-2">
                            <div className="flex gap-3 items-center">
                                <svg style={{color: "white", background: 'red', borderRadius: '50px'}}
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                                </svg>

                                <p className="text-xs font-semibold uppercase tracking-[3.60px]">OVERUSED SKILL</p>
                            </div>
                            <br/>


                            <div className="text-blue-950 text-[13px] font-normal leading-[18.20px] ml-9">
                                <ul className="list-disc">
                                    {overusedItems.length > 0 && overusedItems.map((item, index) => (
                                        <li key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <br/>
                            <div className="flex gap-3 items-center">
                                <svg style={{color: "white", background: 'blue', borderRadius: '50px'}}
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                </svg>

                                <p className="text-xs font-semibold uppercase tracking-[3.60px]">LESS SKILLED</p>
                            </div>
                            <br/>


                            <div className="text-blue-950 text-[13px] font-normal leading-[18.20px] ml-9">
                                <ul className="list-disc">
                                    {lessSkilledItems.length > 0 && lessSkilledItems.map((item, index) => (
                                        <li key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }
                    <br/>
                    <div className="flex justify-between items-center">
                        {!flipped &&
                            <div className="text-slate-500 text-[10px]">FRONT</div>
                        }
                        {flipped &&
                            <div className="text-slate-500 text-[10px]">BACK</div>
                        }
                        <button className="bg-sky-600 w-16 h-8 flex justify-evenly items-center text-white rounded-md"
                                onClick={() => flipCard()}>
                            <p className="text-xs font-semibold capitalize">Flip</p>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"/>
                            </svg>

                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}