import * as React from 'react';
import Main from "../Main";
import Header from "./Header";
import axios from 'axios'
import Footer from "./Footer";

axios.defaults.withCredentials = true;

axios.defaults.headers.common = {
    "Accept": "application/json",
};

function App() {
    return (<>
        <div className="h-full bg-[#F9FAFC]">
            <Header/>
            <Main/>
            <Footer/>
        </div>
    </>)
}

export default App