import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Review_exchangeService from "../../Services/review_exchange.service";
import moment from "moment";

const ratings = [
    {
        id: 1,
        name: 'EE'
    },
    {
        id: 2,
        name: 'AE'
    },
    {
        id: 3,
        name: 'EM'
    }
]

interface IFormInputs {
    parent_id: number
    teamwork: string
    teamwork_rating: bigint
    innovation: string
    innovation_rating: bigint,
    results: string
    results_rating: bigint
}

const schema = yup.object({
    teamwork: yup.string().required('Teamwork is required!').max(2000),
    teamwork_rating: yup.number().oneOf([1, 2, 3], 'Please select one of the offered options!').required('Teamwork rating is required!'),
    innovation: yup.string().required('Innovation is required!').max(2000),
    innovation_rating: yup.number().oneOf([1, 2, 3], 'Please select one of the offered options!').required('Innovation rating is required!'),
    results: yup.string().required('Results is required!').max(2000),
    results_rating: yup.number().oneOf([1, 2, 3], 'Please select one of the offered options!').required('Results rating is required!'),
}).required();

const ReviewsExchangeForm = () => {
    let {parent_id} = useParams();

    let {id} = useParams();
    const [isDraft, setIsDraft] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [isCompleted, setIsCompleted] = useState(0);

    const [exchangeSubmission, setExchangeSubmission] = useState({
        date_shared: "",
        owner: {
            name: "",
            unique_id: ""
        },
        assignee: {
            name: ""
        },
        review_exchanges: [
            {
                unique_id: "",
                updated_at: ""
            }
        ],
        updated_at: ""
    });

    const isAddMode = !id;

    function onSubmit(data: any) {
        return isAddMode
            ? submitForm(data, 0)
            : updateForm(id, data, 0);
    }

    function onSubmitDraft(data: any) {
        return isAddMode
            ? submitForm(data, 1)
            : updateForm(id, data, 1);
    }

    function submitForm(data: any, is_draft: number) {
        setIsLoading(true);
        data.parent_id = parent_id;
        data.is_draft = is_draft;

        return Review_exchangeService.reply(data)
            .then(() => {
                navigate('/manager/connection/' + exchangeSubmission.owner.unique_id)
            })
            .catch();
    }

    function updateForm(id: string | undefined, data: any, isDraft: number) {
        setIsLoading(true);
        data.parent_id = parent_id;
        data.is_draft = isDraft;

        if (id === undefined) {
            return;
        }

        return Review_exchangeService.replyUpdate(id, data)
            .then(() => {
                navigate('/manager/connection/' + exchangeSubmission.owner.unique_id)
            })
            .catch();
    }

    const {setValue, register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (!isAddMode) {
            Review_exchangeService.get('?include=exchangeSubmission,exchangeSubmission.owner,exchangeSubmission.assignee,exchangeSubmission.reviewExchanges&filter[unique_id]=' + id).then(conversationExchange => {
                const fields = ['parent_id', 'teamwork', 'teamwork_rating', 'innovation', 'innovation_rating', 'results', 'results_rating'];

                // @ts-ignore
                fields.forEach(field => setValue(field, conversationExchange.data.data[0][field]));
                setIsDraft(conversationExchange.data.data[0].exchange_submission.is_draft)
                setExchangeSubmission(conversationExchange.data.data[0].exchange_submission);
                setIsCompleted(conversationExchange.data.data[0].exchange_submission.is_completed)
            });
        } else {
            Review_exchangeService.get('?include=exchangeSubmission,exchangeSubmission.owner,exchangeSubmission.assignee,exchangeSubmission.reviewExchanges&filter[unique_id]=' + parent_id).then(conversationExchange => {
                setExchangeSubmission(conversationExchange.data.data[0].exchange_submission);
            });
        }
    }, [id, isAddMode, setValue]);
    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            <div className={"grid grid-cols-1 lg:grid-cols-6 lg:gap-8"}>
                <div
                    className={"grid grid-cols-1 gap-4 lg:col-span-4 " + (!isAddMode && !isDraft ? "lg:col-start-1" : 'lg:col-start-2')}>
                    <section aria-labelledby="section-1-title">
                        <h2 className="sr-only" id="section-1-title">
                            Section title
                        </h2>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <form className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200">
                                                    <div>
                                                        <div>
                                                            <h3 className="text-2xl font-medium leading-6">Review
                                                                Exchange</h3>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                Use this exchange to discuss Teamwork, Innovation, and
                                                                Results with your manager before your
                                                                annual review. Discussing Teamwork, Innovation and
                                                                Results halfway through the year ensures
                                                                you
                                                                have a clear summary of your performance before your
                                                                annual review. You and your leader can
                                                                use
                                                                previous Exchange Journal entries to assess and discuss
                                                                how you fulfilled the expectations
                                                                for your
                                                                role.
                                                            </p>
                                                            <ol style={{listStyleType: "decimal"}}
                                                                className="list-disc text-sm text-black mt-4 ml-4">
                                                                <li>What were your key achievements?</li>
                                                                <li> In what areas could you improve
                                                                    or develop?
                                                                </li>
                                                                <li>Provide examples, especially in areas that you
                                                                    thought you exceeded expectations or
                                                                    didn’t
                                                                    achieve expectations.
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">Teamwork</h3>
                                                            <p className="text-sm mt-1 ">
                                                                Employee can be described in the following ways:
                                                            </p>
                                                            <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                <li>Cultivated relationships and worked
                                                                    collaboratively
                                                                </li>
                                                                <li className={'mt-2'}> Communicated openly and
                                                                    effectively
                                                                </li>
                                                                <li className={'mt-2'}>Positively influenced the
                                                                    performance of the team
                                                                </li>
                                                                <li className={'mt-2'}>Provided leadership and
                                                                    inspiration to others
                                                                </li>
                                                            </ul>
                                                            <p className="text-sm mt-4 text-gray-600">
                                                                Use the space below to review your Teamwork during this
                                                                period
                                                            </p>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    {...register("teamwork")}
                                                                    id="teamwork"
                                                                    name="teamwork"
                                                                    rows={6}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue={''}
                                                                />
                                                                <p>{errors.teamwork?.message}</p>
                                                            </div>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                Choose your Teamwork self-rating from the menu:
                                                            </p>
                                                            <div className={'mt-2'}>
                                                                <select
                                                                    {...register("teamwork_rating")}
                                                                    id="teamwork_rating"
                                                                    name="teamwork_rating"
                                                                    className="mt-1 block w-48 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Canada"
                                                                >
                                                                    <option value={0}> -
                                                                    </option>
                                                                    {
                                                                        ratings.map((x) => <option value={x.id}
                                                                                                   key={x.id}>{x.name}</option>)
                                                                    }
                                                                </select>
                                                                <p>{errors.teamwork_rating?.message}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">Innovation</h3>
                                                            <p className="text-sm mt-1 ">
                                                                Employee demonstrates the following behaviours:
                                                            </p>
                                                            <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                <li>Approached problems with creativity and new ideas
                                                                </li>
                                                                <li className={'mt-2'}> Applied expertise to find better
                                                                    ways to do things
                                                                </li>
                                                                <li className={'mt-2'}>Focused on the details that
                                                                    produce excellence
                                                                </li>
                                                                <li className={'mt-2'}>Took smart, courageous risks</li>
                                                            </ul>
                                                            <p className="text-sm mt-4 text-gray-600">
                                                                Use the space below to review your Innovation during
                                                                this period.
                                                            </p>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    {...register("innovation")}
                                                                    id="innovation"
                                                                    name="innovation"
                                                                    rows={6}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue={''}
                                                                />
                                                                <p>{errors.innovation?.message}</p>
                                                            </div>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                Choose your Innovation self-rating from the menu:
                                                            </p>
                                                            <div className={'mt-2'}>
                                                                <select
                                                                    {...register("innovation_rating")}
                                                                    id="innovation_rating"
                                                                    name="innovation_rating"
                                                                    className="mt-1 block w-48 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Canada"
                                                                >
                                                                    <option value={0}> -
                                                                    </option>
                                                                    {
                                                                        ratings.map((x) => <option value={x.id}
                                                                                                   key={x.id}>{x.name}</option>)
                                                                    }
                                                                </select>
                                                                <p>{errors.innovation_rating?.message}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">Results</h3>
                                                            <p className="text-sm mt-1 ">
                                                                Drive for Results: Employee demonstrates the following
                                                                behaviours to achieve results:
                                                            </p>
                                                            <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                <li>Produced high-quality outcomes</li>
                                                                <li className={'mt-2'}> Accomplished goals and
                                                                    objectives on time
                                                                </li>
                                                                <li className={'mt-2'}>Exercised good judgement and took
                                                                    responsibility for decisions
                                                                </li>
                                                                <li className={'mt-2'}>Enriched the customer
                                                                    experience
                                                                </li>
                                                            </ul>
                                                            <p className="text-sm mt-4 text-gray-600">
                                                                Use the space below to review your Results during this
                                                                period.
                                                            </p>
                                                            <div className="mt-1">
                                                                <textarea
                                                                    {...register("results")}
                                                                    id="results"
                                                                    name="results"
                                                                    rows={6}
                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue={''}
                                                                />
                                                                <p>{errors.results?.message}</p>
                                                            </div>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                Choose your Results self-rating from the menu:
                                                            </p>
                                                            <div className={'mt-2'}>
                                                                <select
                                                                    {...register("results_rating")}
                                                                    id="results_rating"
                                                                    name="results_rating"
                                                                    className="mt-1 block w-48 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue="Canada"
                                                                >
                                                                    <option value={0}>-
                                                                    </option>
                                                                    {
                                                                        ratings.map((x) => <option value={x.id}
                                                                                                   key={x.id}>{x.name}</option>)
                                                                    }
                                                                </select>
                                                                <p>{errors.results_rating?.message}</p>
                                                            </div>
                                                            <p className="mt-1 text-lg font-medium pt-2">
                                                                Performance Abbreviation
                                                            </p>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                <span className="font-bold">EE</span> - Exceeded
                                                                Expectations
                                                            </p>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                <span className="font-bold">AE</span> - Achieved
                                                                Expectations
                                                            </p>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                <span className="font-bold">EM</span> - Expected More
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-5">
                                                    <div className="flex justify-end">
                                                        <Link to={"-1"}
                                                              onClick={() => navigate(-1)}
                                                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            Cancel
                                                        </Link>
                                                        <button
                                                            onClick={handleSubmit(onSubmitDraft)}
                                                            type="submit"
                                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600"
                                                        >
                                                            {isLoading &&
                                                                <div role="status">
                                                                    <svg
                                                                        className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                        viewBox="0 0 100 101" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                            fill="currentColor"/>
                                                                        <path
                                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                            fill="currentFill"/>
                                                                    </svg>
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            }

                                                            {!isLoading &&
                                                                <span>Save as Draft</span>
                                                            }
                                                        </button>
                                                        <button
                                                            onClick={handleSubmit(onSubmit)}
                                                            type="submit"
                                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            {isLoading &&
                                                                <div role="status">
                                                                    <svg
                                                                        className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                        viewBox="0 0 100 101" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                            fill="currentColor"/>
                                                                        <path
                                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                            fill="currentFill"/>
                                                                    </svg>
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            }
                                                            {!isLoading &&
                                                                <span>Reply</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {!isAddMode && !isDraft &&
                    <div className={'lg:col-span-2'}>
                        <ul
                            className=" grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

                            <li key={exchangeSubmission.owner?.name}
                                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                <div
                                    className="flex w-full items-center justify-between space-x-6 p-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="truncate text-sm font-medium text-gray-900">Submitted
                                                by: {exchangeSubmission.owner?.name}</h3>
                                        </div>
                                        <p className="mt-1 truncate text-sm text-gray-500">Date
                                            Shared: {moment(exchangeSubmission.review_exchanges[0].updated_at).format('dddd, MMMM DD, YYYY')}</p>
                                        <p className="mt-1 truncate text-sm text-gray-500">Time
                                            Shared: {moment(exchangeSubmission.review_exchanges[0].updated_at).format('hh:mm A')}</p>
                                        <Link
                                            to={'/reviews-exchange/' + exchangeSubmission.review_exchanges[0].unique_id}>
                                            <button
                                                className={"mt-3 inline-flex justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                                                View Original Submission
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </li>


                            {isCompleted && !isAddMode && !isDraft ?
                                <li key={exchangeSubmission.assignee?.name}
                                    className="mt-5 col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <div
                                        className="flex w-full items-center justify-between space-x-6 p-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">Replied
                                                    by: {exchangeSubmission.assignee?.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Date
                                                Replied: {moment(exchangeSubmission.review_exchanges[1].updated_at).format('dddd, MMMM D, YYYY')}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Time
                                                Shared: {moment(exchangeSubmission.review_exchanges[1].updated_at).format('hh:mm A')}</p>
                                            <Link to={'/reviews-exchange/' + 1}>
                                                <button
                                                    className={"mt-3 inline-flex justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                                                    View Reply
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </li>
                                : null}
                        </ul>
                    </div>
                }
            </div>
        </div>
    </main>);
}
export default ReviewsExchangeForm;