import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class CompetencyCardService {
    get(query: any) {
        return axios.get(API_URL + 'competency-cards' + query, {headers: authHeader()});
    }
}

export default new CompetencyCardService();