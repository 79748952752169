import React, {useEffect, useState} from 'react';
import Card from "../../Components/CardSort/Card";
import CardMinified from "../../Components/CardSort/CardMinified";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import {Droppable} from "../../Components/CardSort/Dropable";
import AuthService from "../../Services/auth/auth.service";
import PositionService from "../../Services/position.service";
import Modal from "../../Components/CardSort/Model";
import {useNavigate, useParams} from "react-router-dom";
import Card_sort_submissionService from "../../Services/card_sort_submission.service";

const initialState = {
    first: [],
    second: [],
    third: [],
    fourth: [],
};

const loggedInUser = AuthService.getCurrentUser();

const CardSort = () => {
    const navigate = useNavigate();
    let {id} = useParams();
    const [items, setItems] = useState(initialState);

    const [modalOpen, setModalOpen] = useState(false);

    const [modalCardId, setModalCardId] = useState<number | null>(null);
    const [modalCardPrimary, setModalCardPrimary] = useState<string | null>(null);
    const [modalCardSecondary, setModalCardSecondary] = useState<string | null>(null);
    const [modalCardSkilledDescription, setModalCardSkilledDescription] = useState<string | null>(null);
    const [modalCardOverusedDescription, setModalCardOverusedDescription] = useState<string | null>(null);
    const [modalCardLessSkilledDescription, setModalCardLessSkilledDescription] = useState<string | null>(null);
    const [modalCardDescription, setModalCardDescription] = useState<string | null>(null);
    const [modalCardName, setModalCardName] = useState<string | null>(null);

    const showModal = (id: number, primary: string, secondary: string, skilled: string, overUsed: string, lessSkilled: string, description: string, name: string) => {
        setModalCardId(id);
        setModalCardPrimary(primary);
        setModalCardSecondary(secondary);
        setModalCardSkilledDescription(skilled);
        setModalCardOverusedDescription(overUsed);
        setModalCardLessSkilledDescription(lessSkilled);
        setModalCardDescription(description);
        setModalCardName(name);
        setModalOpen(true);
    };

    const isAddMode = !id;

    useEffect(function () {
        if (isAddMode) {
            PositionService.get('?include=competencyCards&filter[id]=' + loggedInUser.position_id).then(function (response: any) {
                // @ts-ignore
                if (response.data?.data[0]?.competency_cards === undefined) {
                    return;
                }

                setItems(items => ({
                    ...items,
                    first: response.data?.data[0]?.competency_cards,  // update the value of specific key
                }))
            });
        } else {
            Card_sort_submissionService.get('?filter[unique_id]=' + id + "&include=cardSortSubmissionLines.competencyCard").then(function (response: any) {
                let filteredItems = response.data?.data[0]?.card_sort_submission_lines;

                const firstItems = filteredItems.filter((item: any) => item.card_sort_category_id === 1);
                const secondItems = filteredItems.filter((item: any) => item.card_sort_category_id === 2);
                const thirdItems = filteredItems.filter((item: any) => item.card_sort_category_id === 3);

                setItems(items => ({
                    ...items,
                    second: firstItems.map((x: any) => x.competency_card),  // update the value of specific key
                    third: secondItems.map((x: any) => x.competency_card),  // update the value of specific key
                    fourth: thirdItems.map((x: any) => x.competency_card),  // update the value of specific key
                }))
            });
        }
    }, [id])

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function findContainer(id: any) {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key) => {
            // @ts-ignore
            const containerArray = items[key];
            return containerArray.some((item: { id: any; }) => item.id === id);
        });

    }

    function handleDragStart(event: { active: any; }) {
        const {active} = event;
        const {id} = active;
    }

    function handleDragOver(event: any) {
        const {active, over, draggingRect} = event;

        const {id} = active;

        const {id: overId} = over;

        // Find the containers
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer === overContainer
        ) {
            return;
        }

        setItems((prev: any) => {
            // @ts-ignore
            const activeItems = prev[activeContainer];

            // @ts-ignore
            const overItems = prev[overContainer];

            // Find the indexes for the items
            const activeIndex = activeItems.findIndex((item: { id: any; }) => item.id === id);
            const overIndex = overItems.findIndex((item: { id: any; }) => item.id === overId);

            let newIndex;

            if (overId in prev) {
                // We're at the root droppable of a container
                newIndex = overItems.length + 1;
            } else {
                const isBelowLastItem =
                    over &&
                    overIndex === overItems.length - 1 &&
                    draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

                const modifier = isBelowLastItem ? 1 : 0;

                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            return {
                ...prev,
                [activeContainer]: [
                    // @ts-ignore
                    ...prev[activeContainer].filter((item) => item.id !== active.id)
                ],
                [overContainer]: [
                    ...prev[overContainer].slice(0, newIndex),
                    // @ts-ignore
                    items[activeContainer][activeIndex],
                    ...prev[overContainer].slice(newIndex, prev[overContainer].length)
                ]
            };
        });
    }

    function handleDragEnd(event: { active: any; over: any; }) {
        const {active, over} = event;
        const {id} = active;
        const {id: overId} = over;

        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (
            !activeContainer ||
            !overContainer ||
            activeContainer !== overContainer
        ) {
            return;
        }

        // @ts-ignore
        const activeIndex = items[activeContainer].indexOf(active.id);
        // @ts-ignore
        const overIndex = items[overContainer].indexOf(overId);

        if (activeIndex !== overIndex) {
            setItems((items) => ({
                ...items,
                // @ts-ignore
                [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }));
        }
    }

    function saveCardSort() {
        if (items.first.length > 0) {
            alert('There should be no items in the first column.');
            return;
        }

        let data = {
            skilled: items.second.map((item: any) => item.id),
            overused: items.third.map((item: any) => item.id),
            less_skilled: items.fourth.map((item: any) => item.id),
        };

        return Card_sort_submissionService.create(data)
            .then(() => {
                navigate('/');
            })
            .catch();
    }

    return (<main className="">
        {modalOpen && <Modal isOpen={modalOpen} key={modalCardId}
                             id={modalCardId}
                             primary={modalCardPrimary}
                             secondary={modalCardSecondary}
                             skilled_description={modalCardSkilledDescription}
                             overused_description={modalCardOverusedDescription}
                             less_skilled_description={modalCardLessSkilledDescription}
                             description={modalCardDescription}
                             name={modalCardName}
                             onModalClose={setModalOpen}/>
        }
        <div className="mx-auto max-w-full px-4lg:max-w-full">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
            >
                <div className="grid grid-cols-7 h-screen">
                    <SortableContext
                        items={items.first}
                        strategy={verticalListSortingStrategy}
                    >
                        <div className={'text-center border-r border-zinc-200'}>


                            {(items.second.length > 0 || items.third.length > 0 || items.fourth.length > 0) && items.first.length === 0 && isAddMode &&
                                <button onClick={() => saveCardSort()}
                                        className="mt-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    Share Sort Cards
                                </button>
                            }
                            <Droppable id={'first'}>
                                {items.first.length > 0 && items.first.map(item =>
                                    <div key={item['id']} onClick={() => showModal(
                                        item['id'],
                                        item['primary_text'],
                                        item['secondary_text'],
                                        item['skilled_description'],
                                        item['overused_description'],
                                        item['less_skilled_description'],
                                        item['description'],
                                        item['title']
                                    )}>
                                        <CardMinified
                                            key={item['id']}
                                            id={item['id']}
                                            name={item['title']}/>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className={'col-span-2 p-4 text-center border-r border-zinc-200'}>
                            <div className="text-blue-950 text-lg font-semibold float-left">Skilled</div>
                            <br/>
                            <Droppable id={'second'}>
                                {items.second.length > 0 && items.second.map(item => <Card key={item['id']}
                                                                                           id={item['id']}
                                                                                           primary_text={item['primary_text']}
                                                                                           secondary_text={item['secondary_text']}
                                                                                           skilled_description={item['skilled_description']}
                                                                                           overused_description={item['overused_description']}
                                                                                           less_skilled_description={item['less_skilled_description']}
                                                                                           description={item['description']}
                                                                                           name={item['title']}/>)}
                            </Droppable>
                        </div>
                        <div className={'col-span-2 p-4 text-center border-r border-zinc-200'}>
                            <div className="text-blue-950 text-lg font-semibold float-left">Overused</div>
                            <br/>
                            <Droppable id={'third'}>
                                {items.third.length > 0 && items.third.map(item => <Card key={item['id']}
                                                                                         id={item['id']}
                                                                                         primary_text={item['primary_text']}
                                                                                         secondary_text={item['secondary_text']}
                                                                                         skilled_description={item['skilled_description']}
                                                                                         overused_description={item['overused_description']}
                                                                                         less_skilled_description={item['less_skilled_description']}
                                                                                         description={item['description']}
                                                                                         name={item['title']}/>)}
                            </Droppable>
                        </div>
                        <div className={'col-span-2 p-4 text-center border-r border-zinc-200'}>
                            <div className="text-blue-950 text-lg font-semibold float-left">Less Skilled</div>
                            <br/>
                            <Droppable id={'fourth'}>
                                {items.fourth.length > 0 && items.fourth.map(item => <Card key={item['id']}
                                                                                           id={item['id']}
                                                                                           primary_text={item['primary_text']}
                                                                                           secondary_text={item['secondary_text']}
                                                                                           skilled_description={item['skilled_description']}
                                                                                           overused_description={item['overused_description']}
                                                                                           less_skilled_description={item['less_skilled_description']}
                                                                                           description={item['description']}
                                                                                           name={item['title']}/>)}
                            </Droppable>
                        </div>
                    </SortableContext>
                </div>
            </DndContext>
        </div>
    </main>);
}
export default CardSort