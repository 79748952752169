import React, {useState} from "react";
import AuthService from "../Services/auth/auth.service";

type Props = {}

const ResetPassword: React.FC<Props> = () => {
    const [email, setEmail] = useState<string>("");

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleLogin = (e: { preventDefault: () => void; }) => {
        setIsSubmitting(true);

        e.preventDefault();

        AuthService.sendPasswordReset(email).then(() => function () {

        });
    }

    return (
        <div className="flex h-screen">
            <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div>
                        <img
                            className="mx-auto h-10 w-auto"
                            src="https://adevait.com/img/assets/horizontal_logo_adeva.svg"
                            alt="Your Company"
                        />
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[540px]">
                        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                            <form onSubmit={handleLogin} action="#" method="POST" className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-gray-400 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm border-green-500 hover:border-green-600 text-white bg-green-500 hover:bg-green-600"
                                    >
                                        {isSubmitting && <div>Please wait!</div>}
                                        {!isSubmitting && <div>Send Password Reset Link</div>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
