import React, {Fragment, useEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function TableDraftsCreateButton(props: any) {
    const [isConversationDisabled, setIsConversationDisabled] = useState<boolean>(true);
    const [isGoalAndExperienceDisabled, setIsGoalAndExperienceDisabled] = useState<boolean>(true);
    const [isReviewsExchangeDisabled, setIsReviewsExchangeDisabled] = useState<boolean>(true);

    useEffect(() => {
        setIsConversationDisabled(props.isConversationDisabled);
        setIsGoalAndExperienceDisabled(props.isGoalAndExperienceDisabled);
        setIsReviewsExchangeDisabled(props.isReviewsExchangeDisabled);
    }, [props]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex w-full justify-center rounded-md border border-green-600 hover:border-sky-600 text-white bg-green-600 hover:bg-sky-600 px-4 py-2 text-sm font-medium shadow-sm focus:outline-none">
                    Add new draft
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {isConversationDisabled
                            ? <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="/conversation-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-300' : 'text-gray-300',
                                            'block px-4 py-2 text-sm'
                                        )}
                                        onClick={(event) => event.preventDefault()}>
                                        Conversation Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                            : <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="/conversation-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Conversation Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                        }
                        {isGoalAndExperienceDisabled
                            ? <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="goal-and-experience-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-300' : 'text-gray-300',
                                            'block px-4 py-2 text-sm'
                                        )}
                                        onClick={(event) => event.preventDefault()}>
                                        Goal and Experience Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                            : <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="goal-and-experience-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Goal and Experience Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                        }
                        {isReviewsExchangeDisabled
                            ? <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="reviews-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-300' : 'text-gray-300',
                                            'block px-4 py-2 text-sm'
                                        )}
                                        onClick={(event) => event.preventDefault()}>
                                        Review Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                            : <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="reviews-exchange/create"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                    >
                                        Review Exchange
                                    </Link>
                                )}
                            </Menu.Item>
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}