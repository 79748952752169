import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import moment from "moment";
import FeedbackService from "../../Services/feedback.service";

interface IFormInputs {
    continue_excelling_at: string
    key_opportunity: string
    exchange_submission: [] | null
}

const schema = yup.object({
    continue_excelling_at: yup.string().required('Please fill what the person should be excelling at!').max(1000).nullable(),
    key_opportunity: yup.string().required('Please fill what the key opportunity field!').max(1000).nullable(),
}).required();

const RequestedFeedbackForm = () => {
    let {id} = useParams();

    const [isDraft, setIsDraft] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [exchangeSubmissionId, setExchangeSubmissionsId] = useState('d12uidb1u2ydhbj1h');
    const [exchangeSubmission, setExchangeSubmission] = useState({
        date_shared: "",
        owner: {
            name: ""
        },
        user: {
            name: ""
        },
        assignee: {
            name: ""
        }
    });

    const navigate = useNavigate();

    const isAddMode = !id;

    function onSubmit(data: any) {
        return updateForm(id, data, 0);
    }

    function updateForm(id: string | undefined, data: any, isDraft: number) {
        setIsLoading(true);

        data.is_draft = isDraft;

        if (id === undefined) {
            return;
        }

        return FeedbackService.update(id, data)
            .then(() => {
                navigate('/feedbacks');
            })
            .catch();
    }

    const {setValue, register, handleSubmit, formState: {errors}, control} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (!isAddMode) {
            FeedbackService.get('?include=exchangeSubmission.owner,exchangeSubmission.user,exchangeSubmission.assignee&filter[unique_id]=' + id).then(requestedFeedback => {
                const fields = ['continue_excelling_at', 'key_opportunity', 'exchange_submission']

                // @ts-ignore
                fields.forEach(field => setValue(field, requestedFeedback.data.data[0][field]));

                setIsDraft(requestedFeedback.data.data[0].exchange_submission.is_draft)
                setExchangeSubmissionsId(requestedFeedback.data.data[0].exchange_submission.id);
                setExchangeSubmission(requestedFeedback.data.data[0].exchange_submission);
            });
        }
    }, [id, isAddMode, setValue]);

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className={"grid grid-cols-1 lg:grid-cols-6 lg:gap-8"}>
                <div
                    className={"grid grid-cols-1 gap-4 lg:col-span-4 " + (!isAddMode && !isDraft ? "lg:col-start-1" : 'lg:col-start-2')}>
                    <section aria-labelledby="section-1-title">
                        <h2 className="sr-only" id="section-1-title">
                            Section title
                        </h2>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <form onSubmit={handleSubmit(onSubmit)}
                                                  className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200">
                                                    <div>
                                                        <div
                                                            className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 ">
                                                                <h3 className="text-xl font-medium leading-6 mt-8">1.
                                                                    What are a few things that you have observed this
                                                                    person excelling at that they should continue?</h3>
                                                                <p className="text-sm mt-1 text-gray-600">
                                                                    Please describe the specific situations, behaviours,
                                                                    and the impact they had.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("continue_excelling_at")}
                                                                        disabled={isDraft === 0}
                                                                        id="continue_excelling_at"
                                                                        name="continue_excelling_at"
                                                                        rows={6}
                                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                        defaultValue={''}
                                                                        placeholder={'Your response...'}
                                                                    />
                                                                    <p>{errors.continue_excelling_at?.message}</p>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-6 ">
                                                                <h3 className="text-xl font-medium leading-6 mt-8">
                                                                    2. What is the one key opportunity to improve that
                                                                    you think would make a real difference in their
                                                                    performance?
                                                                </h3>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("key_opportunity")}
                                                                        disabled={isDraft === 0}
                                                                        id="key_opportunity"
                                                                        name="key_opportunity"
                                                                        rows={6}
                                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                        defaultValue={''}
                                                                        placeholder={'Your response...'}
                                                                    />
                                                                    <p>{errors.key_opportunity?.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pt-5">
                                                    <div className="flex justify-end">
                                                        <Link to={"/"}
                                                              onClick={() => navigate(-1)}
                                                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            Cancel
                                                        </Link>
                                                        {isDraft === 1 &&
                                                            <button
                                                                disabled={isLoading}
                                                                onClick={handleSubmit(onSubmit)}
                                                                type="submit"
                                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                {isLoading &&
                                                                    <div role="status">
                                                                        <svg
                                                                            className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                            viewBox="0 0 100 101" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"/>
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"/>
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }
                                                                {!isLoading &&
                                                                    <span>Share</span>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {
                    !isAddMode && !isDraft &&
                    <div className="grid grid-cols-1 col-span-2 gap-4">
                        <div>
                            <ul
                                className=" grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                <li key={'filip.stojkovski13@gmail.com'}
                                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <div
                                        className="flex w-full items-center justify-between space-x-6 p-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">Requested
                                                    by: {exchangeSubmission.owner?.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Date
                                                Shared: {moment(exchangeSubmission.date_shared).format('dddd, MMMM D, YYYY')}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Time
                                                Shared: {moment(exchangeSubmission.date_shared).format('hh:mm A')}</p>
                                        </div>
                                    </div>
                                    <div
                                        className="flex w-full items-center justify-between space-x-6 p-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">Feedback
                                                    provided by: {exchangeSubmission.user?.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Date
                                                Shared: {moment(exchangeSubmission.date_shared).format('dddd, MMMM D, YYYY')}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Time
                                                Shared: {moment(exchangeSubmission.date_shared).format('hh:mm A')}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    </main>)
        ;
}
export default RequestedFeedbackForm;