import axios from "axios";
import authHeader from "./auth.header";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
    async login(email: string, password: string) {
        const self = this;

        return await this.getToken().then(response => {
            return self.loginWithCredentials(email, password).then(response => {
                localStorage.setItem('token', JSON.stringify(response.data))

                axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;

                return self.fetchUser().then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data.user))

                    localStorage.setItem('role', JSON.stringify(response.data.role[0]))

                    if (response.data.role[0].name === 'Superuser') {
                        window.location.replace("/admin");
                    }
                    
                    if (localStorage.getItem('intended_route') !== "") {
                        // @ts-ignore
                        window.location.replace(localStorage.getItem('intended_route'));
                        return;
                    }

                    window.location.replace("/");
                })
            });
        });
    }

    async getToken() {
        return axios.get(API_URL + '/sanctum/csrf-cookie');
    }

    async loginWithCredentials(email: string, password: string) {
        return axios.post(API_URL + '/api/login', {
            email: email,
            password: password
        });
    }

    async fetchUser() {
        return axios.get(API_URL + '/api/user');
    }

    changePassword(password: string, password_confirmation: string) {
        return axios.post(API_URL + '/api/reset-password', {
            password: password,
            password_confirmation: password_confirmation
        }, {headers: authHeader()}).then(response => {
            localStorage.setItem('user', JSON.stringify(response.data))
            window.location.href = "/";
        })
    }

    async sendPasswordReset(email: string) {
        return await this.getToken().then(response => {
            return axios.post(API_URL + '/api/reset-password', {
                email: email,
            }, {headers: authHeader()}).then(response => {
                window.location.href = "/";
            })
        });
    }

    async resetPasswordConfirmation(email: string, token: string, password: string, password_confirmation: string) {
        return await this.getToken().then(response => {
            return axios.post(API_URL + '/api/reset-password-confirmation', {
                email: email,
                token: token,
                password: password,
                password_confirmation: password_confirmation
            }, {headers: authHeader()}).then(response => {
                this.login(email, password).then(r => console.log(r))
            })
        });
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");

        if (userStr) return JSON.parse(userStr);

        return null;
    }

    getCurrentRole() {
        const roleString = localStorage.getItem("role");

        if (roleString) return JSON.parse(roleString);

        return null;
    }
}

export default new AuthService();
