import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class MonthlyRatingService {
    get(query: string) {
        return axios.get(API_URL + 'monthly-review' + query, {headers: authHeader()});
    }

    getForUser(userId: string, year: number) {
        return axios.get(API_URL + 'monthly-review/' + userId + '/' + year, {headers: authHeader()});
    }
    update(id: number, data: any) {
        return axios.put(API_URL + 'monthly-review/update', data, {headers: authHeader()});
    }
}

export default new MonthlyRatingService();