import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class ExchangeSubmissionService {
    get(query: any) {
        this.getToken();
        return axios.get(API_URL + 'exchange-submissions' + query, {headers: authHeader()});
    }

    getManager(query: any) {
        this.getToken()
        return axios.get(API_URL + 'exchange-submissions/manager' + query, {headers: authHeader()});
    }

    create(data: any) {
        this.getToken()
        return axios.post(API_URL + 'exchange-submissions', data, {headers: authHeader()});
    }

    destroy(uniqueId: string) {
        this.getToken()
        return axios.delete(API_URL + `exchange-submissions/destroy/${uniqueId}`, {headers: authHeader()});
    }

    notifyPeer(uniqueId: string) {
        return axios.post(API_URL + 'exchange-submissions/notify-peer', {unique_id: uniqueId}, {headers: authHeader()});
    }

    getToken() {
        return axios.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie');
    }
}

export default new ExchangeSubmissionService();