import {Fragment} from 'react'
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import AuthService from "../Services/auth/auth.service";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname === '/login' || location.pathname === '/reset-password' || location.pathname === '/reset-password-form') {
        return (
            <></>
        )
    }

    const logout = () => {
        AuthService.logout()

        navigate('/login');
    };

    const role = AuthService.getCurrentRole();

    return (
        <>
            <Disclosure as="nav" className="border-b border-gray-200">
                {({open}) => (
                    <>
                        <div className="mx-auto max-w px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 justify-between">
                                <div className="flex">
                                    <div className="flex flex-shrink-0 items-center">
                                        <NavLink to="/">
                                            <img
                                                className="block h-8 w-auto lg:hidden"
                                                src="https://adevait.com/img/assets/horizontal_logo_adeva.svg"
                                                alt="Your Company"
                                            />
                                        </NavLink>
                                        <NavLink to="/" className="h-full flex items-center border-r border-gray-300">
                                            <img
                                                className="hidden h-8 w-auto lg:block pr-4 sm:pr-6 lg:pr-8"
                                                src="https://adevait.com/img/assets/horizontal_logo_adeva.svg"
                                                alt="Your Company"
                                            />
                                        </NavLink>
                                        <NavLink to="/feedbacks" className={'block ml-8 mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-blue-500'}>
                                            Feedback
                                        </NavLink>
                                        <NavLink to="/monthly-review" className={'block ml-8 mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-blue-500'}>
                                            Monthly Ratings
                                        </NavLink>
                                        <NavLink to="/card-sort" className={'block ml-8 mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-blue-500'}>
                                            Sort Cards
                                        </NavLink>
                                    </div>
                                </div>

                                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button
                                                className="flex max-w-xs items-center rounded-full bg-white text-sm">
                                                <span className="sr-only">Open user menu</span>
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {role !== null && role.name === 'Superuser' && location.pathname !== "/admin" &&
                                                    <Menu.Item key={'admin'}>
                                                        {({active}) => (
                                                            <Link
                                                                to={'/admin'}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                Admin Dashboard
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                }
                                                {role !== null && role.name === 'Superuser' && location.pathname !== "/admin/positions" &&
                                                    <Menu.Item key={'admin_positions'}>
                                                        {({active}) => (
                                                            <Link
                                                                to={'/admin/positions'}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                Admin Dashboard Positions
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                }
                                                {role !== null && (role.name === 'Manager') && location.pathname !== '/manager' &&
                                                    <Menu.Item key={'manager'}>
                                                        {({active}) => (
                                                            <Link
                                                                to={'/manager'}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                My Connection Team
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                }
                                                <Menu.Item key={'logout'}>
                                                    {({active}) => (
                                                        <a href={'/'}
                                                           onClick={logout}
                                                           className={classNames(
                                                               active ? 'bg-gray-100' : '',
                                                               'block px-4 py-2 text-sm text-gray-700'
                                                           )}
                                                        >
                                                            Logout
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                        <Disclosure.Panel className="sm:hidden">
                            <div className="border-t border-gray-200 pt-4 pb-3">
                                <div className="flex items-center px-4">
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800"></div>
                                        <div className="text-sm font-medium text-gray-500"></div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1">
                                    {role !== null && role.name === 'Manager' && location.pathname !== 'admin' &&
                                        <Disclosure.Button
                                            key={'admin'}
                                            as="a"
                                            href={'/admin'}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Admin
                                        </Disclosure.Button>
                                    }
                                    <Disclosure.Button
                                        onClick={logout}
                                        key={'logout'}
                                        as="a"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        Logout
                                    </Disclosure.Button>
                                    {role !== null && role.name === 'Manager' &&
                                        <Disclosure.Button
                                            key={'manager'}
                                            as="a"
                                            href={'/manager'}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Manager
                                        </Disclosure.Button>
                                    }
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    )
}

export default Header