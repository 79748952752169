import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import PositionService from "../../Services/position.service";
import Competency_cardService from "../../Services/competency_card.service";
import ComboboxMultiple from "../../Components/Positions/ComboboxMultiple";

interface IFormInputs {
    name: string,
    competency_cards: []
}

const schema = yup.object({
    name: yup.string().required().max(255),
    competency_cards: yup.array().nullable(),
}).required();

const PositionForm = () => {
    const [competencyCards, setCompetencyCards] = useState([]);

    const [selectedPeople, setSelectedPeople] = useState([]);

    let {id} = useParams();

    const navigate = useNavigate();

    const isAddMode = !id;

    function onSubmit(data: any) {
        return isAddMode
            ? submitForm(data)
            : updateForm(id, data);
    }

    const setCompetenciesFromSelect = function (data: any) {
        setSelectedPeople(data);
    }

    function submitForm(data: any) {
        // @ts-ignore
        data.competency_cards = selectedPeople.map(obj => obj.id);

        return PositionService.create(data)
            .then(() => {
                navigate('/admin/positions');
            })
            .catch();
    }

    function updateForm(id: string | undefined, data: any) {
        if (id === undefined) {
            return;
        }

        // @ts-ignore
        data.competency_cards = selectedPeople.map(obj => obj.id);

        return PositionService.update(id, data)
            .then(() => {
                navigate('/admin/positions');
            })
            .catch();
    }

    const {setValue, register, handleSubmit, formState: {errors}, control} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        Competency_cardService.get('').then(response => {
            setCompetencyCards(response.data.data)
        });

        if (!isAddMode) {
            PositionService.get('?include=competencyCards&filter[unique_id]=' + id).then(position => {
                const fields = ['name'];

                // @ts-ignore
                fields.forEach(field => setValue(field, position.data.data[0][field]));

                setSelectedPeople(position.data.data[0].competency_cards);
            });
        }
    }, [id, isAddMode, setValue]);

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-0 lg:grid-cols-1 lg:gap-0">
                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Position Information</h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Position Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            {...register("name")}
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="given-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                                        />
                                        <p>{errors.name?.message}</p>
                                    </div>
                                </div>
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="{'competency_cards'}"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Competencies
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        {competencyCards.length !== 0 &&
                                            <ComboboxMultiple
                                                selectedPeople={selectedPeople}
                                                setCompetenciesFromSelect={setCompetenciesFromSelect}
                                                competencyCards={competencyCards}
                                            />
                                        }
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link to={"-1"}
                                  onClick={() => navigate(-1)}
                                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Cancel
                            </Link>
                            <button
                                onClick={handleSubmit(onSubmit)}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>);
}
export default PositionForm;