import DataTable, { TableColumn } from 'react-data-table-component';
import React, { useEffect, useState, useCallback } from "react";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import { Link } from "react-router-dom";
import moment from "moment/moment";

interface DataRow {
    exchange_id: number;
    user: any;
    owner: any;
    assignee: any;
    created_at: string;
    date_shared: string;
    updated_at: string;
    requested_feedback: any[]; // Add this line if it's not already defined
}

export default function FeedbackDatatable(props: any) {
    const notifyPeer = (uniqueId: string) => {
        Exchange_submissionService.notifyPeer(uniqueId);
        setNotificationSent(true);
    };

    const type = props.type;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [notificationSent, setNotificationSent] = useState(false);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    const formatActionType = (row: DataRow, type: number) => {
        const isRequestCompleted = row.requested_feedback[0]?.is_completed;

        if (type === 3 && isRequestCompleted !== true) {
            return (
                <div>
                    <button
                        onClick={() => notifyPeer(row.requested_feedback[0]?.unique_id)}
                        className={`ml-3 text-indigo-600 cursor-pointer hover:text-indigo-900 underline`}
                        disabled={notificationSent}
                    >
                        {notificationSent ? 'Notification Sent' : 'Notify Peer'}
                    </button>
                </div>
            );
        }

        if (type === 4) {
            return (
                <>
                    <div>
                        <Link to={'/requested-feedback/' + row.requested_feedback[0]?.unique_id}
                              className={'ml-3 cursor-pointer text-blue-600 hover:text-blue-900 hover:underline'}>
                            View Feedback
                        </Link>
                    </div>
                </>
            );
        }

        return (
            <div>
                <Link to={'/requested-feedback/' + row.requested_feedback[0]?.unique_id}
                      className={'ml-3 cursor-pointer text-blue-600 hover:text-blue-900 hover:underline'}>
                    Share Feedback
                </Link>
            </div>
        );
    };

    const fetchExchangeSubmissions = useCallback(async (page: number) => {
        setLoading(true);

        let response = null;

        if (type === 1) {
            response = await Exchange_submissionService.get(`/feedback/shared?filter[is_completed]=0&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (type === 2) {
            response = await Exchange_submissionService.get(`/feedback/shared?filter[is_completed]=1&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (type === 3) {
            response = await Exchange_submissionService.get(`/feedback/received?filter[is_completed]=0&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (type === 4) {
            response = await Exchange_submissionService.get(`/feedback/received?filter[is_completed]=1&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (response != null) {
            setData(response.data.data);
            setTotalRows(response.data.total);
        }

        setLoading(false);
    }, [perPage, type]);

    const handlePageChange = (page: any) => {
        fetchExchangeSubmissions(page).then();
    };

    const formatReceivedSent = (row: any, type: number) => {
        if (type === 1 || type === 2) {
            return row.owner.name;
        }

        return row.assignee.name;
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        let response = null;

        if (props.type === 1) {
            response = await Exchange_submissionService.get(`/feedback/shared?filter[is_completed]=0&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (props.type === 2) {
            response = await Exchange_submissionService.get(`/feedback/shared?filter[is_completed]=1include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (props.type === 3) {
            response = await Exchange_submissionService.get(`/feedback/received?filter[is_completed]=0&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (props.type === 4) {
            response = await Exchange_submissionService.get(`/feedback/received?filter[is_completed]=1&include=user,owner,assignee,requestedFeedback&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`);
        }

        if (response != null) {
            setData(response.data.data);
            setPerPage(newPerPage);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchExchangeSubmissions(1).then();
    }, [fetchExchangeSubmissions, props]);

    const columns: TableColumn<DataRow>[] = [
        {
            name: type === 1 || type === 2 ? 'Requested By' : 'Requested From',
            selector: row => formatReceivedSent(row, type),
        },
        {
            name: 'Request Date',
            selector: row => moment(row.updated_at).format('ddd, MMM DD, YYYY'),
        },
        {
            name: '',
            selector: row => formatActionType(row, type),
        },
    ];

    return (
        <div>
            <DataTable
                title=""
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );
}