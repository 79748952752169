import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Goal_and_experience_exchangeService from "../../Services/goal_and_experience_exchange.service";
import CommentSection from "../../Components/Comments/CommentSection";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import {Listbox} from "../../Components/GoalAndExperienceExchange/Listbox";
import {ComboBox} from "../../Components/GoalAndExperienceExchange/Combobox";
import moment from "moment";

type PublishOption = {
    id: number;
    title: string;
    description: string;
};

const publishingOptions: PublishOption[] = [
    {
        id: 1,
        title: 'Address a few things in current role',
        description: 'In this growth opportunity, you’re identifying and addressing things within your control that might be slowing \n' +
            'your growth or impeding your ability to do your best work. ',
    },
    {
        id: 2,
        title: 'Deepen expertise in current role',
        description: 'In this growth opportunity, you are going deeper - or operating at a range of levels - in your area of expertise \n' +
            'in your current role.',
    },
    {
        id: 3,
        title: 'Change scope in current role',
        description: 'In this growth opportunity, you are adding something new or different or more to the work you do every day. \n' +
            'You’re building on your current expertise, relationships, and credibility while still trying something new.',
    },
    {
        id: 4,
        title: 'Explore a potential new role',
        description: 'In this growth opportunity, you explore a move to another role in your group or another group, with a \n' +
            'significant change in responsibilities, environment, or both.',
    },
]

interface IFormInputs {
    growth_opportunity: number
    competency: number
    goal: string
    goal_time: string
    competency_vision: string
    competency_subject: string
    competency_support: string
    actions: string
    actions_support: string
    debrief: string
    exchange_submission: [] | null
}

const schema = yup.object({
    growth_opportunity: yup.number().required('This field is required.'),
    competency: yup.number().required('This field is required.'),
    goal: yup.string().required('This field is required.').max(1000, 'This field must be at most 1000 characters'),
    goal_time: yup.string().required('This field is required.'),
    competency_vision: yup.string().required('This field is required.').max(1000, 'This field must be at most 1000 characters'),
    competency_subject: yup.string().required('This field is required.'),
    competency_support: yup.string().required('This field is required.').max(1000, 'This field must be at most 1000 characters'),
    actions: yup.string().required('This field is required.'),
    actions_support: yup.string().required('This field is required.').max(1000, 'This field must be at most 1000 characters'),
    debrief: yup.string().max(1000, 'This field must be at most 1000 characters'),
}).required();

const GoalAndExperienceExchangeForm = () => {
    let {id} = useParams();

    const [isDraft, setIsDraft] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [exchangeSubmissionId, setExchangeSubmissionsId] = useState('d12uidb1u2ydhbj1h');
    const [exchangeSubmission, setExchangeSubmission] = useState({
        date_shared: "",
        owner: {
            name: ""
        }
    });

    const [characterCounts, setCharacterCounts] = useState(
        {
            goal: 0,
            competency_vision: 0,
            competency_support: 0,
            actions: 0,
            actions_support: 0,
            debrief: 0
        }
    );
    const maxCharacterCount = 1000;

    const handleTextareaChange = (name: string) => (event: { target: { value: string | any[]; }; }) => {
        const currentCount = event.target.value.length;
        setCharacterCounts((prevCounts) => ({
            ...prevCounts,
            [name]: currentCount,
        }));
    };

    const navigate = useNavigate();

    const isAddMode = !id;

    function onSubmit(data: any) {
        return isAddMode
            ? submitForm(data, 0)
            : updateForm(id, data, 0);
    }

    function onSubmitDraft(data: any) {
        return isAddMode
            ? submitForm(data, 1)
            : updateForm(id, data, 1);
    }

    function submitForm(data: any, isDraft: number) {
        setIsLoading(true);

        data.is_draft = isDraft;

        return Goal_and_experience_exchangeService.create(data)
            .then(() => {
                navigate('/');
            })
            .catch();

    }

    function updateForm(id: string | undefined, data: any, isDraft: number) {
        setIsLoading(true);

        data.is_draft = isDraft;

        if (id === undefined) {
            return;
        }

        return Goal_and_experience_exchangeService.update(id, data)
            .then(() => {
                navigate('/');
            })
            .catch();
    }

    function deleteSubmission(exchangeSubmissionId: string) {
        setIsLoading(true);

        return Exchange_submissionService.destroy(exchangeSubmissionId)
            .then(() => {
                navigate('/');
            })
            .catch();

    }

    const {setValue, register, handleSubmit, formState: {errors}, control} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (!isAddMode) {
            Goal_and_experience_exchangeService.get('?include=exchangeSubmission.owner&filter[unique_id]=' + id).then(conversationExchange => {

                const fields = ['competency', 'growth_opportunity', 'goal', 'goal_time', 'competency_vision', 'competency_subject', 'competency_support', 'actions', 'actions_support', 'debrief', 'exchange_submission']

                // @ts-ignore
                fields.forEach(field => setValue(field, conversationExchange.data.data[0][field]));

                setIsDraft(conversationExchange.data.data[0].exchange_submission.is_draft)

                setExchangeSubmissionsId(conversationExchange.data.data[0].exchange_submission.id);
                setExchangeSubmission(conversationExchange.data.data[0].exchange_submission);
            });
        }
    }, [id, isAddMode, setValue]);

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className={"grid grid-cols-1 lg:grid-cols-6 lg:gap-8"}>
                <div
                    className={"grid grid-cols-1 gap-4 lg:col-span-4 " + (!isAddMode && !isDraft ? "lg:col-start-1" : 'lg:col-start-2')}>
                    <section aria-labelledby="section-1-title">
                        <h2 className="sr-only" id="section-1-title">
                            Section title
                        </h2>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <form onSubmit={handleSubmit(onSubmit)}
                                                  className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200">
                                                    <div>
                                                        <div>
                                                            <h3 className="text-2xl font-medium leading-6">Goal and
                                                                Experience Exchange</h3>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                Adeva provides the opportunity for individuals to
                                                                contribute meaningfully to something big -
                                                                and
                                                                through that contributions to do our best work. We can
                                                                continue to grow and develop in our
                                                                roles
                                                                and careers at Adeva by pursuing opportunities that
                                                                allow us to deepen expertise, take on
                                                                more
                                                                challenging responsibilities, and move towards a better
                                                                version of ourselves.
                                                                Be thoughtful and deliberate about what growth and
                                                                development opportunities are right for
                                                                you,
                                                                the specific goals that will create focus, and the
                                                                action steps and experiences that will
                                                                shape your
                                                                plan. Sharing your plan with your leader enables you to
                                                                explore how you want to develop and
                                                                what
                                                                support you will need.
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">Opportunity</h3>
                                                            <p className="text-sm mt-1 text-gray-600">
                                                                Select the growth and development opportunity that feels
                                                                right for you right now.
                                                            </p>
                                                            <p className="text-sm text-black mt-3">
                                                                Select one growth opportunity from the list below:
                                                            </p>
                                                            <div className={'mt-5'}>
                                                                <Listbox name="growth_opportunity"
                                                                         control={control}
                                                                         rules={{required: true}}
                                                                         publishingOptions={publishingOptions}
                                                                />
                                                                <p className="text-red-500 text-sm mt-1">{errors.growth_opportunity?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Goal
                                                                </label>
                                                                <p className="text-sm text-black mt-3">
                                                                    With your growth and development opportunity in
                                                                    mind, what specific goal do you want to
                                                                    achieve?
                                                                </p>
                                                                <p className="text-sm mt-1 text-gray-600">
                                                                    Describe what success looks like, including
                                                                    accomplishments, improvements, and results.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("goal")}
                                                                        disabled={isDraft === 0}
                                                                        id="goal"
                                                                        name="goal"
                                                                        rows={6}
                                                                        className={`block w-full rounded ${errors.goal ? 'border-red-500' : 'border-gray-300'} ${characterCounts.goal > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        defaultValue={''}
                                                                        onChange={handleTextareaChange('goal')}
                                                                        placeholder={'For all the cases assigned to me, I am expected to progress the case from start to finish. \n' +
                                                                            'I will have 10 working days to prepare and progress the work from the date it reaches my work queue.\n'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.goal?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.goal > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.goal}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-6 mt-4">
                                                                <p className="text-sm text-black mt-3">
                                                                    Goals are time-bound. When do you expect to achieve
                                                                    this goal?
                                                                </p>
                                                                <div className="mt-4">
                                                                    <input
                                                                        {...register("goal_time")}
                                                                        disabled={isDraft === 0}
                                                                        type="text"
                                                                        name="goal_time"
                                                                        placeholder={'Example: By the end of this quarter'}
                                                                        id="goal_time"
                                                                        className={`block w-full rounded ${errors.goal_time ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                e.preventDefault(); // Prevent form submission on Enter key press
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p className="text-red-500 text-sm mt-1">{errors.goal_time?.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="mt-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Competencies and Functional Expertise
                                                                </label>
                                                                <p className="text-sm mt-1">
                                                                    With your growth opportunity and specific goal in
                                                                    mind, identify competencies and
                                                                    functional
                                                                    expertise that will help you achieve your goal.
                                                                </p>
                                                                <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                    <li>Core competencies are observable and measurable
                                                                        skills and behaviours that
                                                                        contribute to job
                                                                        success. They are the transferable skills you
                                                                        can take with you wherever you go
                                                                        throughout your
                                                                        career.
                                                                    </li>
                                                                    <li className={'mt-2'}>Functional expertise is the
                                                                        subject-matter knowledge and
                                                                        functional or technical skills associated
                                                                        with a specific job or role.
                                                                    </li>
                                                                </ul>
                                                                <p className="text-sm mt-4">
                                                                    To be successful, you will want to focus your
                                                                    development activities. If you identify
                                                                    more than one
                                                                    competency and one aspect of functional expertise,
                                                                    consider capturing these in future
                                                                    Goal and
                                                                    Experience exchanges.
                                                                </p>
                                                                <p className="text-sm mt-4">
                                                                    If appropriate to achieve your goal, <span
                                                                    className={'font-bold'}>select a
                                                                    competency </span> that you will need to develop,
                                                                    leverage or
                                                                    use differently.
                                                                </p>
                                                                <p className="text-sm mt-3 text-gray-600">
                                                                    Access the FYI: For Your Improvement book to review
                                                                    the 38 competencies in Competency
                                                                    Architecture.
                                                                </p>
                                                                <div className="mt-2">
                                                                    <ComboBox
                                                                        control={control}
                                                                        name={'competency'}
                                                                    />
                                                                    <p className="text-red-500 text-sm mt-1">{errors.competency?.message}</p>
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-6 mt-1">
                                                                <p className="text-sm mt-1">
                                                                    How do you envision skilled use of this competency?
                                                                </p>

                                                                <div className="mt-4">
                                                                <textarea
                                                                    {...register("competency_vision")}
                                                                    disabled={isDraft === 0}
                                                                    id="competency_vision"
                                                                    name="competency_vision"
                                                                    rows={6}
                                                                    onChange={handleTextareaChange('competency_vision')}
                                                                    className={`block w-full rounded ${errors.competency_vision ? 'border-red-500' : 'border-gray-300'} ${characterCounts.competency_vision > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                    placeholder={'Describe the specific behaviours that can be observed and measured to demonstrate skilled use of this competency and how this competency will support your goal. \n' +
                                                                        'Access the FYI: For Your Improvement book for more information about competencies.'}
                                                                />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.competency_vision?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.competency_vision > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.competency_vision}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <p className="text-sm mt-5">
                                                                    If appropriate to achieve your goal, identify
                                                                    functional expertise that you will need to
                                                                    develop,
                                                                    leverage, or use differently.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <input
                                                                        {...register("competency_subject")}
                                                                        disabled={isDraft === 0}
                                                                        type="text"
                                                                        name="competency_subject"
                                                                        id="competency_subject"
                                                                        className={`block w-full rounded ${errors.competency_subject ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        placeholder={'Briefly describe subject-matter knowledge or functional or technical skill.'}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                e.preventDefault(); // Prevent form submission on Enter key press
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p className="text-red-500 text-sm mt-1">{errors.competency_subject?.message}</p>
                                                                </div>
                                                                <p className="text-sm mt-5">
                                                                    How does this functional expertise support your
                                                                    goal?
                                                                </p>

                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("competency_support")}
                                                                        disabled={isDraft === 0}
                                                                        id="competency_support"
                                                                        name="competency_support"
                                                                        rows={6}
                                                                        onChange={handleTextareaChange('competency_support')}
                                                                        className={`block w-full rounded ${errors.competency_support ? 'border-red-500' : 'border-gray-300'} ${characterCounts.competency_support > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        placeholder={'For functional knowledge - describe how acquiring and applying this knowledge will support your goal. \n' +
                                                                            'For functional/technical skill - describe the specific behaviours that can be observed and measured to demonstrate proficiency and how this skill will support your goal.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.competency_support?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.competency_support > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.competency_support}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="mt-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Actions and Experiences
                                                                </label>
                                                                <p className="text-sm text-black mt-3">
                                                                    Remember, growth doesn’t happen all at once. It
                                                                    accumulates bit by bit. The important
                                                                    thing is that
                                                                    you are taking active, consistent, and deliberate
                                                                    steps to drive your own growth.
                                                                    Describe the actions and experiences that will help
                                                                    you build your capabilities and
                                                                    reach your
                                                                    growth and development goal.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("actions")}
                                                                        disabled={isDraft === 0}
                                                                        id="actions"
                                                                        name="actions"
                                                                        rows={6}
                                                                        onChange={handleTextareaChange('actions')}
                                                                        className={`block w-full rounded ${errors.actions ? 'border-red-500' : 'border-gray-300'} ${characterCounts.actions > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        defaultValue={''}
                                                                        placeholder={'Describe your actions and experiences. Consider small, concrete actions you may practice daily or regularly. \n' +
                                                                            'Also, consider different ways of developing through structured training, interactions with others, and experiences.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.actions?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.actions > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.actions}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-6 mt-4">
                                                                <p className="text-sm text-black mt-3">
                                                                    What support do you need to achieve your plan?
                                                                </p>
                                                                <div className="mt-4">
                                                                    <div className="mt-4">
                                                                        <textarea
                                                                            {...register("actions_support")}
                                                                            disabled={isDraft === 0}
                                                                            id="actions_support"
                                                                            name="actions_support"
                                                                            rows={6}
                                                                            onChange={handleTextareaChange('actions_support')}
                                                                            className={`block w-full rounded ${errors.actions_support ? 'border-red-500' : 'border-gray-300'} ${characterCounts.actions_support > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                            defaultValue={''}
                                                                            placeholder={'Enter a description of the support needed to achieve this plan. \n' +
                                                                                'Consider what resources, time, approval, or peer or leader connections will support success. \n' +
                                                                                'Be specific about the type of feedback that will help you measure progress.'}
                                                                        />
                                                                        <div className="flex items-center justify-between">
                                                                            <p className="text-red-500 text-sm mt-1">{errors.actions_support?.message}</p>
                                                                            <p className="mt-1 flex justify-end text-xs">
                                                                                <span className={`${characterCounts.actions_support > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.actions_support}</span>
                                                                                <span>/{maxCharacterCount}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className={'text-sm text-black mt-3'}>
                                                                    Once you have your initial exchange with your
                                                                    manager, put your plan into action. Use
                                                                    the
                                                                    Conversation Exchange to guide mid-plan updates and
                                                                    progress with your manager. Once
                                                                    you’ve
                                                                    executed your plan, return to this change and
                                                                    prepare the Debrief: reflection and Next
                                                                    Steps portion
                                                                    to reflect with your manager on what you achieved
                                                                    and your next steps should be.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="mt-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Debrief: Reflection and Next Steps
                                                                </label>
                                                                <p className="text-sm text-black mt-3">
                                                                    Once you’ve completed your plan, reflect on how it
                                                                    went.
                                                                </p>
                                                                <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                    <li>
                                                                        What progress have you made today towards your
                                                                        growth and development opportunity
                                                                        and
                                                                        specific goal?
                                                                    </li>
                                                                    <li className={'mt-2'}>
                                                                        What is your current skill in the competency
                                                                        and/or functional expertise you
                                                                        identified to support
                                                                        your goal?
                                                                    </li>
                                                                    <li className={'mt-2'}>
                                                                        What was easier than you expected? What was more
                                                                        difficult?
                                                                    </li>
                                                                </ul>
                                                                <p className={'text-sm text-black mt-3'}>
                                                                    Based on what has changed since you created this
                                                                    plan, what do you want your next step
                                                                    to be?
                                                                    Perhaps, you want to create a new plan to continue
                                                                    working on the existing opportunity
                                                                    or a plan to
                                                                    work on a new goal.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("debrief")}
                                                                        disabled={isDraft === 0}
                                                                        id="debrief"
                                                                        name="debrief"
                                                                        rows={6}
                                                                        onChange={handleTextareaChange('debrief')}
                                                                        className={`block w-full rounded ${errors.debrief ? 'border-red-500' : 'border-gray-300'} ${characterCounts.debrief > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        defaultValue={''}
                                                                        placeholder={'Enter notes about your progress toward your goal, your development of competencies and/or functional expertise, and your reflection on the overall experience. \n' +
                                                                            'Describe how this goal has supported the Growth Opportunity you selected. \n' +
                                                                            'Identify what you want next steps to be, then discuss with your manager.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.debrief?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.debrief > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.debrief}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pt-5">
                                                    <div className="flex justify-end">
                                                        <Link to={"/"}
                                                              onClick={() => navigate(-1)}
                                                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            Cancel
                                                        </Link>
                                                        {isDraft === 1 && id &&
                                                            <button
                                                                disabled={isLoading}
                                                                onClick={() => deleteSubmission(exchangeSubmissionId)}
                                                                type="submit"
                                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                {isLoading &&
                                                                    <div role="status">
                                                                        <svg
                                                                            className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                            viewBox="0 0 100 101" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"/>
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"/>
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }

                                                                {!isLoading &&
                                                                    <span>Delete Draft</span>
                                                                }
                                                            </button>
                                                        }
                                                        {isDraft === 1 &&
                                                            <button
                                                                disabled={isLoading}
                                                                onClick={handleSubmit(onSubmitDraft)}
                                                                type="submit"
                                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600"
                                                            >
                                                                {isLoading &&
                                                                    <div role="status">
                                                                        <svg
                                                                            className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                            viewBox="0 0 100 101" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"/>
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"/>
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }

                                                                {!isLoading &&
                                                                    <span>Save as Draft</span>
                                                                }
                                                            </button>
                                                        }
                                                        {isDraft === 1 &&
                                                            <button
                                                                disabled={isLoading}
                                                                onClick={handleSubmit(onSubmit)}
                                                                type="submit"
                                                                className="ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm border-green-600 hover:border-green-700 text-white bg-green-600 hover:bg-green-700"
                                                            >
                                                                {isLoading &&
                                                                    <div role="status">
                                                                        <svg
                                                                            className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                            viewBox="0 0 100 101" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"/>
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"/>
                                                                        </svg>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }
                                                                {!isLoading &&
                                                                    <span>Share</span>
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {!isAddMode && !isDraft &&
                    <div className="grid grid-cols-1 col-span-2 gap-4">
                        <div>
                            <ul
                                className=" grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                <li key={'filip.stojkovski13@gmail.com'}
                                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <div
                                        className="flex w-full items-center justify-between space-x-6 p-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">Submitted
                                                    by: {exchangeSubmission.owner?.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Date
                                                Shared: {moment(exchangeSubmission.date_shared).format('dddd, MMMM D, YYYY')}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Time
                                                Shared: {moment(exchangeSubmission.date_shared).format('hh:mm A')}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <CommentSection owner={exchangeSubmission.owner} submissionId={exchangeSubmissionId}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    </main>);
}
export default GoalAndExperienceExchangeForm;