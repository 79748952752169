import React from 'react';
import CardSortDatatable from "../../Components/CardSort/CardSortDatatable";
import {Link} from "react-router-dom";

const CardSortIndex = () => {
    return (<main className="mt-12 pb-8">
            <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
                <h1 className="sr-only">Card Sort</h1>
                <div className="mt-5 grid grid-cols-1 items-start gap-3">
                    <div className="grid grid-cols-1 col-span-2">
                        <section aria-labelledby="section-1-title">
                            <div className="overflow-hidden rounded-lg bg-white shadow">
                                <Link to={'/card-sort/create'}>
                                    <button
                                        className={"m-3 inline-flex justify-center rounded-md border border-transparent border-green-500 hover:border-sky-600 text-white bg-green-500 hover:bg-sky-600 py-2 px-4 text-sm font-medium shadow-sm"}>
                                        Create
                                    </button>
                                </Link>
                                <div className="p-3">
                                    <CardSortDatatable/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default CardSortIndex