function Footer() {
    return (
        <>
            <footer>
                <div className="mx-auto max-w px-4 sm:px-6 lg:px-8 text-right">
                    <div className=" py-8 text-center text-sm text-gray-500 sm:text-left lg:text-right">
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer