import {Route, Routes} from 'react-router-dom';
import Home from "./Views/Home";
import Login from "./Views/Login";
import Admin from "./Views/Users/Admin";
import React from "react";
import ConversationExchange from "./Views/ConversationExchange/ConversationExchangeForm";
import ReviewsExchangeForm from "./Views/ReviewsExchange/ReviewsExchangeForm";
import GoalAndExperienceExchangeForm from "./Views/GoalAndExperienceExchange/GoalAndExperienceExchangeForm";
import ProtectedRoute from "./Components/Auth/ProtectedRoute";
import Manager from "./Views/Manager";
import ReviewsExchangeReplyForm from "./Views/ReviewsExchange/ReviewsExchangeReplyForm";
import UserForm from "./Views/Users/UserForm";
import axios from "axios";
import ManagerConnections from "./Views/ManagerConnections";
import ChangePassword from "./Views/ChangePassword";
import ResetPassword from "./Views/ResetPassword";
import ResetPasswordForm from "./Views/ResetPasswordForm";
import AdminArchived from "./Views/Users/AdminArchived";
import AdminNotAssigned from "./Views/Users/AdminNonAssigned";
import MonthlyReview from "./Views/MonthlyReview/MonthlyReview";
import Feedback from "./Views/Feedback/Feedback";
import RequestedFeedbackForm from "./Views/RequestedFeedback/RequestedFeedbackForm";
import AdminPositions from "./Views/Users/AdminPositions";
import PositionForm from "./Views/Users/PositionForm";
import CardSort from "./Views/CardSort/CardSort";
import CardSortIndex from "./Views/CardSort/CardSortIndex";
import CardSortIndexConnection from "./Views/CardSort/CardSortIndexConnection";

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        window.location.replace("/login");
    }

    return Promise.reject(error)
})

const Main = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/reset-password' element={<ResetPassword/>}/>
            <Route path='/reset-password-form' element={<ResetPasswordForm/>}/>

            <Route path='/change-password' element={<ProtectedRoute><ChangePassword/></ProtectedRoute>}/>

            <Route path='/' element={<ProtectedRoute><Home/></ProtectedRoute>}/>

            <Route path='/admin' element={<ProtectedRoute><Admin/></ProtectedRoute>}/>
            <Route path='/admin/archived' element={<ProtectedRoute><AdminArchived/></ProtectedRoute>}/>
            <Route path='/admin/non-assigned' element={<ProtectedRoute><AdminNotAssigned/></ProtectedRoute>}/>

            <Route path='/admin/positions' element={<ProtectedRoute><AdminPositions/></ProtectedRoute>}/>
            <Route path='/admin/positions/create' element={<ProtectedRoute><PositionForm/></ProtectedRoute>}/>
            <Route path='/admin/positions/:id' element={<ProtectedRoute><PositionForm/></ProtectedRoute>}/>

            <Route path='/users/create' element={<ProtectedRoute><UserForm/></ProtectedRoute>}/>
            <Route path='/users/:id' element={<ProtectedRoute><UserForm/></ProtectedRoute>}/>

            <Route path='/manager' element={<ProtectedRoute><Manager/></ProtectedRoute>}/>
            <Route path='/manager/connection/:id' element={<ProtectedRoute><ManagerConnections/></ProtectedRoute>}/>

            <Route path='/conversation-exchange/create'
                   element={<ProtectedRoute><ConversationExchange/></ProtectedRoute>}/>
            <Route path='/conversation-exchange/:id'
                   element={<ProtectedRoute><ConversationExchange/></ProtectedRoute>}/>

            <Route path='/goal-and-experience-exchange/create'
                   element={<ProtectedRoute><GoalAndExperienceExchangeForm/></ProtectedRoute>}/>
            <Route path='/goal-and-experience-exchange/:id'
                   element={<ProtectedRoute><GoalAndExperienceExchangeForm/></ProtectedRoute>}/>

            <Route path='/reviews-exchange/create'
                   element={<ProtectedRoute><ReviewsExchangeForm/></ProtectedRoute>}/>
            <Route path='/reviews-exchange/:id'
                   element={<ProtectedRoute><ReviewsExchangeForm/></ProtectedRoute>}/>

            <Route path='/requested-feedback/create'
                   element={<ProtectedRoute><RequestedFeedbackForm/></ProtectedRoute>}/>
            <Route path='/requested-feedback/:id'
                   element={<ProtectedRoute><RequestedFeedbackForm/></ProtectedRoute>}/>

            <Route path='/reviews-exchange/:parent_id/reply'
                   element={<ProtectedRoute><ReviewsExchangeReplyForm/></ProtectedRoute>}/>
            <Route path='/reviews-exchange/:parent_id/reply/:id'
                   element={<ProtectedRoute><ReviewsExchangeReplyForm/></ProtectedRoute>}/>

            <Route path='/monthly-review/'
                   element={<ProtectedRoute><MonthlyReview/></ProtectedRoute>}/>

            <Route path='/monthly-review'
                   element={<ProtectedRoute><MonthlyReview/></ProtectedRoute>}/>
            <Route path='/monthly-review/:user_id'
                   element={<ProtectedRoute><MonthlyReview/></ProtectedRoute>}/>

            <Route path='/feedbacks/'
                   element={<ProtectedRoute><Feedback/></ProtectedRoute>}/>

            <Route path='/card-sort/'
                   element={<ProtectedRoute><CardSortIndex/></ProtectedRoute>}/>

            <Route path='/card-sort-connection/:id'
                   element={<ProtectedRoute><CardSortIndexConnection/></ProtectedRoute>}/>

            <Route path='/card-sort/create'
                   element={<ProtectedRoute><CardSort/></ProtectedRoute>}/>

            <Route path='/card-sort/:id'
                   element={<ProtectedRoute><CardSort/></ProtectedRoute>}/>
        </Routes>
    );
}


export default Main;