import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState} from "react";
import PositionService from "../../Services/position.service";
import {Link} from "react-router-dom";

interface DataRow {
    id: number;
    role: string;
    email: string;
    name: string;
    manager: any;
    managees: any;
    unique_id: string;
}

const columns: TableColumn<DataRow>[] = [
    {
        name: 'Position Name',
        selector: row => row.name,
    },
    {
        name: '',
        selector: row => row.unique_id,
        format: row =>
            <h2>
                <Link to={'/admin/positions/' + row.unique_id} className={'text-indigo-600 hover:text-indigo-900'}>
                    Edit
                </Link>
            </h2>
    },
];
export default function PositionsDatatableComponent() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    function runSearch() {
        fetchPositions(1, searchTerm);
    }

    const fetchPositions = async (page: number, searchTerm: string = '') => {
        setLoading(true);

        let searchString = ``;

        if (searchTerm !== "") {
            searchString = `&filter[name]=${searchTerm}`;
        }

        const response = await PositionService.get(`?sort=name${searchString}&page=${page}&per_page=${perPage}&delay=1`)

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (page: any) => {
        fetchPositions(page);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        const response = await PositionService.get(`?sort=name&page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchPositions(1);
    }, []);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    // @ts-ignore

    return (
        <div>
            <div className={'grid grid-cols-3 w-full'}>
                <div>
                    <Link to={'/admin/positions/create'}>
                        <button
                            className={"ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                            Create Position
                        </button>
                    </Link>
                </div>
                <div></div>
                <div className={'grid grid-cols-3'}>
                    <div></div>
                    <div className="relative rounded-md shadow-sm w-48">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input onChange={e => setSearchTerm(e.target.value)}
                               type="search" name="search" id="search"
                               className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               placeholder="Search"/>
                    </div>
                    <div>
                        <button onClick={e => runSearch()}
                                className={'ml-2    rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'}>
                            Search
                        </button>
                    </div>
                </div>
            </div>

            <DataTable
                title="Positions"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );

}