import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Card from "./Card";

export default function Modal(props: {
    isOpen: any;
    id: any;
    primary: any;
    secondary: any;
    skilled_description: any;
    overused_description: any;
    less_skilled_description: any;
    description: any;
    name: any;
    onModalClose: any
}) {
    const [open] = useState(props.isOpen)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.onModalClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5 ">
                                        <div className="mt-2">
                                            <Card key={props.id}
                                                  id={props.id}
                                                  initial_open={false}
                                                  primary_text={props.primary}
                                                  secondary_text={props.secondary}
                                                  skilled_description={props.skilled_description}
                                                  overused_description={props.overused_description}
                                                  less_skilled_description={props.less_skilled_description}
                                                  description={props.description}
                                                  name={props.name}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border-green-600 hover:border-sky-600 text-white bg-green-600 hover:bg-sky-600 px-3 py-2 text-sm font-semibold text-white"
                                        onClick={() => props.onModalClose()}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}