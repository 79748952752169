import React from "react";
import {useDraggable} from "@dnd-kit/core";

export default function CardMinified(props: any) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
        data: {
            card_id: props.id,
        },
    });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <div ref={setNodeRef} key={props.id} style={style} {...listeners} {...attributes}
             className="hover:cursor-grabbing w-11/12 p-3 bg-white rounded-[5px] shadow border border-zinc-200 items-start gap-2.5 inline-flex mt-2">
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2">
                    <div className="text-blue-950 text-[13px] font-normal" style={{ minWidth: "18px" }}>
                        {props.id}
                    </div>
                    <div className="w-px h-[18px] bg-zinc-200"></div>
                    <div className="text-blue-950 text-[13px] font-medium capitalize text-left">
                        {props.name}
                    </div>
                </div>

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-3.5 h-3.5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}