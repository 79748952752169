import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import FeedbackDatatable from "../../Components/FeedbackRequested/FeedbackDatatable";
import FeedbackRequest from "../../Components/FeedbackRequested/FeedbackRequest";

const Feedback = () => {
    const navigate = useNavigate();

    const [type, setType] = useState(1);
    const [showActionsShared, setShowActionsShared] = useState(true);
    const [showActionsReceived, setShowActionsReceived] = useState(false);

    const [isFeedbackSharedSelected, setIsFeedbackSharedSelected] = useState(true);
    const [isFeedbackReceivedSelected, setIsFeedbackReceivedSelected] = useState(false);

    const handleActionsSharedClick = () => {
        setType(1);
        setShowActionsShared(true);
        setShowActionsReceived(false);
        setIsFeedbackSharedSelected(true);
        setIsFeedbackReceivedSelected(false);
    };

    const handleActionsReceivedClick = () => {
        setType(3);
        setShowActionsShared(false);
        setShowActionsReceived(true);
        setIsFeedbackSharedSelected(false);
        setIsFeedbackReceivedSelected(true);
    };

    const handlePendingClick = () => {
        setType(showActionsShared ? 1 : 3);
    };

    const handleCompletedClick = () => {
        setType(showActionsShared ? 2 : 4);
    };

    // @ts-ignore
    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">

            <h1 className="sr-only">Admin</h1>
            <div className="row">
                <button
                    onClick={() => handleActionsSharedClick()}
                    className={`py-2 px-4 text-sm font-medium ${
                        isFeedbackSharedSelected ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-700'
                    } hover:text-blue-500 focus:outline-none border-b-2 focus:border-b-2 ${
                        isFeedbackSharedSelected ? 'focus:border-blue-500' : 'focus:border-gray-500'
                    } hover:border-b-2 hover:border-blue-500`}
                >
                    Feedback Shared
                </button>

                <button
                    onClick={() => handleActionsReceivedClick()}
                    className={`py-2 px-4 text-sm font-medium ${
                        isFeedbackReceivedSelected ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-700'
                    } hover:text-blue-500 focus:outline-none border-b-2 focus:border-b-2 ${
                        isFeedbackReceivedSelected ? 'focus:border-blue-500' : 'focus:border-gray-500'
                    } hover:border-b-2 hover:border-blue-500`}
                >
                    Feedback Received
                </button>
            </div>

            {/* Main 3 column grid */}

            <div className="mt-5 grid grid-cols-3 items-start gap-3">
                {/* Left column */}

                <div className="grid grid-cols-1 col-span-2">
                    <section aria-labelledby="section-1-title">
                        <div className="mb-2 justify-end flex">
                            {showActionsShared &&
                                <div>
                                    <button
                                        onClick={() => handlePendingClick()}
                                        className={`rounded-md border bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm ${
                                            type === 1 ? 'border-blue-500' : 'border-gray-300 hover:bg-gray-50 hover:border-blue-500 focus:border-blue-500'
                                        }`}
                                    >
                                        Pending
                                    </button>

                                    <button
                                        onClick={() => handleCompletedClick()}
                                        className={`ml-3 rounded-md border bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm ${
                                            type === 2 ? 'border-blue-500' : 'border-gray-300 hover:bg-gray-50 hover:border-blue-500 focus:border-blue-500'
                                        }`}
                                    >
                                        Completed
                                    </button>
                                </div>
                            }

                            {showActionsReceived &&
                                <div>
                                    <button
                                        onClick={() => handlePendingClick()}
                                        className={`rounded-md border bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm ${
                                            type === 3 ? 'border-blue-500' : 'border-gray-300 hover:bg-gray-50 hover:border-blue-500 focus:border-blue-500'
                                        }`}
                                    >
                                        Pending
                                    </button>

                                    <button
                                        onClick={() => handleCompletedClick()}
                                        className={`ml-3 rounded-md border bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm ${
                                            type === 4 ? 'border-blue-500' : 'border-gray-300 hover:bg-gray-50 hover:border-blue-500 focus:border-blue-500'
                                        }`}
                                    >
                                        Completed
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3">
                                <FeedbackDatatable type={type}/>
                            </div>
                        </div>
                    </section>
                </div>
                {/* Right column */}
                <div>
                    < FeedbackRequest />
                </div>
            </div>
        </div>
    </main>
);
}
export default Feedback