import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from "react";
import {Link} from "react-router-dom";
import UserService from "../../Services/user.service";

interface DataRow {
    name: string;
    id: number;
    role: string;
    email: any;
    owner: any;
    assignee: any;
}

const columns: TableColumn<DataRow>[] = [
    {
        name: 'Name',
        selector: row => formatViewAction(row),
    },
    {
        name: 'Role',
        selector: row => row.role,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
];

function formatViewAction(row: any) {
    return <h2>
        <Link to={'/manager/connection/' + row.unique_id} className={'text-indigo-600 hover:text-indigo-900'}>
            {row.name}
        </Link>
    </h2>
}

export default function ManagerUserDatatableComponent() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchExchangeSubmissions = useCallback(async (page: number) => {
        setLoading(true);

        const response = await UserService.getConnections(`?sort=name&page=${page}&per_page=${perPage}&delay=1`)

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }, [perPage]);

    const handlePageChange = (page: any) => {
        fetchExchangeSubmissions(page).then(r => console.log());
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        const response = await UserService.getConnections(`?sort=name&page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchExchangeSubmissions(1).then(r => console.log(''));
    }, [fetchExchangeSubmissions]);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    return (
        <div>
            <DataTable
                title="My Connection Team"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );

}