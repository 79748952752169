import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class ConversationExchangeService {
    get(query: string) {
        return axios.get(API_URL + 'card-sort' + query, {headers: authHeader()});
    }

    create(data: any) {
        return axios.post(API_URL + 'card-sort', data, {headers: authHeader()});
    }

    update(id: string, data: any) {
        return axios.put(API_URL + 'card-sort/' + id, data, {headers: authHeader()});
    }
}

export default new ConversationExchangeService();