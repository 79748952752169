import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import UserService from "../../Services/user.service";
import PositionService from "../../Services/position.service";

interface IFormInputs {
    email: string,
    name: string,
    position: string
    is_manager: number
    manager_id: number
    position_id: number
    is_active: number
}

const schema = yup.object({
    email: yup.string().required().email().max(255),
    name: yup.string().required().max(255),
    position: yup.string().nullable().max(255),
    is_manager: yup.number().required(),
    manager_id: yup.string().nullable().notRequired(),
    position_id: yup.string().nullable().notRequired(),
    is_active: yup.number().required(),
}).required();

const UserForm = () => {
    let {id} = useParams();

    const [managers, setManagers] = useState([]);
    const [positions, setPositions] = useState([]);

    const navigate = useNavigate();

    const isAddMode = !id;

    function onSubmit(data: any) {
        return isAddMode
            ? submitForm(data)
            : updateForm(id, data);
    }

    function submitForm(data: any) {
        return UserService.create(data)
            .then(() => {
                navigate('/admin');
            })
            .catch();

    }

    function updateForm(id: string | undefined, data: any) {
        if (id === undefined) {
            return;
        }

        return UserService.update(id, data)
            .then(() => {
                navigate('/admin');
            })
            .catch();
    }

    const {setValue, register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        PositionService.get(`?sort=name`).then(result => {
            setPositions(result.data.data)
        });

        UserService.getManagers(`?sort=name`).then(result => {
            setManagers(result.data.data)

            if (!isAddMode) {
                UserService.get('?include[roleType]&filter[unique_id]=' + id).then(user => {
                    const fields = ['email', 'name', 'role', 'position', 'manager_id', 'position_id', 'is_active'];

                    // @ts-ignore
                    fields.forEach(field => setValue(field, user.data.data[0][field]));

                    setValue('is_manager', user.data.data[0]['roleId'])
                });
            }
        });
    }, [id, isAddMode, setValue]);

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-0 lg:grid-cols-1 lg:gap-0">
                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Employee Information</h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Employee Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            {...register("name")}
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="given-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                                        />
                                        <p>{errors.name?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="username"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Email
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <div className="flex max-w-lg rounded-md shadow-sm">
                                            <input
                                                {...register("email")}
                                                type="text"
                                                name="email"
                                                id="email"
                                                autoComplete="email"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                                            />
                                            <p>{errors.email?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Position
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select {...register("position_id")} name="position_id" id="position_id"
                                                className={'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm'}>
                                            <option value="0">- Select -</option>
                                            {
                                                positions.map((x: any) => <option value={x.id}
                                                                                  key={x.id}>{x.name}</option>)
                                            }
                                        </select>
                                        <p>{errors.position_id?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Is Employee a Connection Manager?
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                            {...register("is_manager")}
                                            name="is_manager"
                                            id="is_manager"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                                        >
                                            <option value="0">- Select -</option>
                                            <option value="3">No</option>
                                            <option value="2">Yes</option>
                                        </select>
                                        <p>{errors.is_manager?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Assign Connection Manager
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select {...register("manager_id")} name="manager_id" id="manager_id"
                                                className={'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm'}>
                                            <option value="0">- Select -</option>
                                            {
                                                managers.map((x: any) => <option value={x.id}
                                                                                 key={x.id}>{x.name}</option>)
                                            }
                                        </select>
                                        <p>{errors.manager_id?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div
                                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="first-name"
                                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Is Employee currently active at Adeva?
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select {...register("is_active")} name="is_active" id="is_active"
                                                className={'block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm'}>
                                            <option value="">- Select -</option>
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                        <p>{errors.is_active?.message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link to={"-1"}
                                  onClick={() => navigate(-1)}
                                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Cancel
                            </Link>
                            <button
                                onClick={handleSubmit(onSubmit)}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>);
}
export default UserForm;