import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from "react";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Card_sort_submissionService from "../../Services/card_sort_submission.service";

interface DataRow {
    exchange_id: number;
    user: any;
    owner: any;
    assignee: any;
    created_at: string;
    date_shared: string;
    updated_at: string;
}

function formatActionType(row: any) {
    return <div>
        <Link to={'/card-sort/' + row.unique_id}
              className={'ml-3 text-indigo-600 hover:text-indigo-900'}>
            View
        </Link>
    </div>
}

export default function CardSortDatatable(props: any) {
    const type = props.type;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Connection Manager',
            selector: row => formatReceivedSent(row, type),
        },
        {
            name: 'Date Shared',
            selector: row => moment(row.updated_at).format('ddd, MMM DD, YYYY'),
        },
        {
            name: '',
            selector: row => formatActionType(row),
        },
    ];

    const fetchExchangeSubmissions = useCallback(async (page: number) => {
        setLoading(true);

        let response = null;

        if (props.user) {
            response = await Card_sort_submissionService.get(`/connection/${props.user}?include=user,assignee&page=${page}&per_page=${perPage}&delay=1&sort=-created_at`)
        } else {
            response = await Card_sort_submissionService.get(`/owned?include=user,assignee&page=${page}&per_page=${perPage}&delay=1&sort=-created_at`)
        }

        if (response != null) {
            setData(response.data.data);
            setTotalRows(response.data.total);
        }

        setLoading(false);
    }, [perPage, type]);

    const handlePageChange = (page: any) => {
        fetchExchangeSubmissions(page).then();
    };

    const formatReceivedSent = (row: any, type: number) => {
        if (type === 1) {
            return row.assignee.name;
        }

        return row.assignee.name;
    }

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        let response = null;

        if (props.user) {
            response = await Card_sort_submissionService.get(`/connection/${props.user}?include=user,assignee&page=${page}&per_page=${perPage}&delay=1&sort=-created_at`)
        } else {
            response = await Card_sort_submissionService.get(`/owned?include=user,assignee&page=${page}&per_page=${perPage}&delay=1&sort=-created_at`)
        }

        if (response != null) {
            setData(response.data.data);
            setPerPage(newPerPage);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchExchangeSubmissions(1).then();
    }, [fetchExchangeSubmissions, props]);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    return (
        <div>
            <DataTable
                title=""
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );

}