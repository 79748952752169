import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class RequestFeedbackService {
    get(query: string) {
        return axios.get(API_URL + 'requested-feedback' + query, {headers: authHeader()});
    }

    dispatchRequest(data: any) {
        return axios.post(API_URL + 'requested-feedback/dispatch-request', data, {headers: authHeader()});
    }

    update(id: string, data: any) {
        return axios.put(API_URL + 'requested-feedback/' + id, data, {headers: authHeader()});
    }
}

export default new RequestFeedbackService();