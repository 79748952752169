import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class ConversationExchangeService {
    get(query: string) {
        this.getToken();
        return axios.get(API_URL + 'conversation-exchanges' + query, {headers: authHeader()});
    }

    create(data: any) {
        this.getToken();
        return axios.post(API_URL + 'conversation-exchanges', data, {headers: authHeader()});
    }

    update(id: string, data: any) {
        this.getToken();
        return axios.put(API_URL + 'conversation-exchanges/' + id, data, {headers: authHeader()});
    }

    getToken() {
        return axios.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie');
    }
}

export default new ConversationExchangeService();