import React, {useState} from "react";
import AuthService from "../Services/auth/auth.service";
import authService from "../Services/auth/auth.service";
import {Link} from "react-router-dom";

type Props = {}

const Login: React.FC<Props> = () => {
    authService.logout();

    const [email, setEmail] = useState<string>("");

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [password, setPassword] = useState<string>("");

    const handleLogin = async (e: { preventDefault: () => void; }) => {
        setIsSubmitting(true);

        e.preventDefault();

        try {
            await AuthService.login(email, password).then(() => function () {
            });
        } catch (e: any) {
            alert(e.response.data);

            setIsSubmitting(false);
        }
    }

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
            <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="https://adevait.com/img/assets/horizontal_logo_adeva.svg"
                        alt="Your Company"
                    />
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[540px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form onSubmit={handleLogin} className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-gray-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-gray-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:outline-none focus:border-transparent"
                                    />
                                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                                        Remember me
                                    </label>
                                </div>

                                <div className="text-sm leading-6">
                                    <Link to={'/reset-password'} className="text-sky-600 hover:text-sky-800">
                                        Reset Password
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm border-green-500 hover:border-green-600 text-white bg-green-500 hover:bg-green-600"
                                >
                                    {isSubmitting && <div>Please wait!</div>}
                                    {!isSubmitting && <div>Login</div>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
        //
        // <div className="flex h-screen">
        //     <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        //         <div className="mx-auto w-full max-w-sm lg:w-96">
        //             <div>
        //                 <img
        //                     className="h-12 w-auto"
        //                     src="https://adevait.com/img/assets/logo_adeva.svg"
        //                     alt="Your Company"
        //                 />
        //                 <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in to your
        //                     account</h2>
        //             </div>
        //
        //             <div className="mt-8">
        //                 <div>
        //                     <div className="relative mt-6">
        //                         <div className="absolute inset-0 flex items-center" aria-hidden="true">
        //                             <div className="w-full border-gray-300"/>
        //                         </div>
        //                     </div>
        //                 </div>
        //
        //                 <div className="mt-6">
        //                     <form onSubmit={handleLogin} action="#" method="POST" className="space-y-6">
        //                         <div>
        //                             <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        //                                 Email address
        //                             </label>
        //                             <div className="mt-1">
        //                                 <input
        //                                     id="email"
        //                                     name="email"
        //                                     type="email"
        //                                     autoComplete="email"
        //                                     required
        //                                     onChange={e => setEmail(e.target.value)}
        //                                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        //                                 />
        //                             </div>
        //                         </div>
        //
        //                         <div className="space-y-1">
        //                             <label htmlFor="password" className="block text-sm font-medium text-gray-700">
        //                                 Password
        //                             </label>
        //                             <div className="mt-1">
        //                                 <input
        //                                     id="password"
        //                                     name="password"
        //                                     type="password"
        //                                     autoComplete="current-password"
        //                                     onChange={e => setPassword(e.target.value)}
        //                                     required
        //                                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        //                                 />
        //                             </div>
        //                         </div>
        //
        //                         <div className="flex items-center justify-between">
        //                             <div className="flex items-center">
        //                                 <input
        //                                     id="remember-me"
        //                                     name="remember-me"
        //                                     type="checkbox"
        //                                     className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        //                                 />
        //                                 <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
        //                                     Remember me
        //                                 </label>
        //                             </div>
        //                             <div className="flex items-center">
        //                                 <Link to={'/reset-password'} className="ml-2 block text-sm text-gray-900">
        //                                     Reset Password
        //                                 </Link>
        //                             </div>
        //                         </div>
        //
        //                         <div>
        //                             <button
        //                                 disabled={isSubmitting}
        //                                 type="submit"
        //                                 className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        //                             >
        //                                 {isSubmitting && <div>Please wait!</div>}
        //                                 {!isSubmitting && <div>Sign in</div>}
        //                             </button>
        //                         </div>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="relative hidden w-0 flex-1 lg:block">
        //         <img
        //             className="absolute inset-0 h-full w-full object-cover"
        //             src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        //             alt=""
        //         />
        //     </div>
        // </div>
    );
};

export default Login;
