export default function authHeader() {
    const userStr = localStorage.getItem("user");

    const userToken = localStorage.getItem("token");

    let user = null;

    if (userStr)
        user = JSON.parse(userStr);

    if (user && userToken) {
        return {Authorization: 'Bearer ' + JSON.parse(userToken).token};
    } else {
        return {Authorization: ''};
    }
}