import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class ReviewExchangeService {
    get(query: string) {
        this.getToken()
        return axios.get(API_URL + 'review-exchanges' + query, {headers: authHeader()});
    }

    create(data: any) {
        this.getToken()
        return axios.post(API_URL + 'review-exchanges', data, {headers: authHeader()});
    }

    reply(data: any) {
        this.getToken()
        return axios.post(API_URL + 'review-exchanges/reply', data, {headers: authHeader()});
    }

    replyUpdate(id: string, data: any) {
        this.getToken()
        return axios.put(API_URL + 'review-exchanges/' + id + '/reply', data, {headers: authHeader()});
    }

    update(id: string, data: any) {
        this.getToken()
        return axios.put(API_URL + 'review-exchanges/' + id, data, {headers: authHeader()});
    }

    getToken() {
        return axios.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie');
    }
}

export default new ReviewExchangeService();