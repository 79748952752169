import axios from 'axios';
import authHeader from "./auth/auth.header";

const API_URL = process.env.REACT_APP_API_URL + '/api/v1/';

class UserService {
    get(query: any) {
        this.getToken()
        return axios.get(API_URL + 'users' + query, {headers: authHeader()});
    }

    getConnections(query: any) {
        this.getToken()
        return axios.get(API_URL + 'users/connections' + query, {headers: authHeader()});
    }

    create(data: any) {
        this.getToken()
        return axios.post(API_URL + 'users', data, {headers: authHeader()});
    }

    getManagers(query: any) {
        this.getToken()
        return axios.get(API_URL + 'users/managers' + query, {headers: authHeader()});
    }

    update(id: string, data: any) {
        this.getToken()
        return axios.put(API_URL + 'users/' + id, data, {headers: authHeader()});
    }

    getToken() {
        return axios.get(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie');
    }
}

export default new UserService();