import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/20/solid";
import Monthly_ratingService from "../../Services/monthly_rating.service";
import AuthService from "../../Services/auth/auth.service";

const ratings = [
    {
        id: 1,
        name: 'EE'
    },
    {
        id: 2,
        name: 'AE'
    },
    {
        id: 3,
        name: 'EM'
    }
]

const MonthlyReview = () => {
    let {user_id} = useParams();

    let user = {
        unique_id: '123123'
    };

    const role = AuthService.getCurrentRole();

    if (!user_id) {
        user = JSON.parse(localStorage.getItem('user') ?? "");
    }

    const [isLoading, setIsLoading] = useState(false);

    const [yearChosen, setYearChosen] = useState(new Date().getFullYear());

    const [editMode, setEditMode] = useState(false);

    const [selectedValues, setSelectedValues] = useState(Array(36).fill(null));

    const navigate = useNavigate();

    const handleSubmit = () => {
        setIsLoading(true);

        let data = {
            assignee_id: user_id ?? user.unique_id,
            year: yearChosen,
            values: selectedValues,
        }

        return Monthly_ratingService.update(1, data)
            .then(() => {
                navigate('/manager/connection/' + user_id);
            })
            .catch(() => {
                setIsLoading(false)
            });
    }

    const handleSelectChange = (index: number, event: { target: { value: any; }; }) => {
        const {value} = event.target;

        setSelectedValues((prevValues) => {
            const updatedValues = [...prevValues];

            updatedValues[index] = value;

            return updatedValues;
        });
    };

    const checkDisabled = (key: number) => {
        if (editMode) {
            return false;
        }

        if (user_id === undefined) {
            return true;
        }

        const currentDate = new Date();

        const monthNumber = currentDate.getMonth();

        const year = currentDate.getFullYear();

        if (yearChosen > year) {
            return true;
        }

        if ((key + 1) === (monthNumber - 3)) {
            return false;
        }

        return true;
    }

    function goToPreviousYear() {
        setYearChosen(yearChosen - 1)
    }

    function goToNextYear() {
        setYearChosen(yearChosen + 1)
    }

    useEffect(function () {
        if (user_id !== undefined) {
            Monthly_ratingService.getForUser(user_id, yearChosen).then(function (response) {

                if (response.data.data.length === 0) {
                    setSelectedValues(Array(36).fill(null))
                    return;
                }

                var arr: React.SetStateAction<any[]> = [];

                response.data.data.monthly_rating_reviews.map(function (row: any) {
                    // @ts-ignore
                    return arr[row.month] = row.rating;
                })

                // @ts-ignore
                setSelectedValues(arr);
            });
        } else {
            Monthly_ratingService.getForUser(user.unique_id, yearChosen).then(function (response) {

                if (response.data.data.length === 0) {
                    setSelectedValues(Array(36).fill(null))
                    return;
                }

                var arr: React.SetStateAction<any[]> = [];

                response.data.data.monthly_rating_reviews.map(function (row: any) {
                    // @ts-ignore
                    return arr[row.month] = row.rating;
                })

                // @ts-ignore
                setSelectedValues(arr);
            });
        }
    }, [yearChosen])


    // @ts-ignore
    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className={"grid grid-cols-1 lg:grid-cols-1"}>
                <div
                    className={"grid grid-cols-1 gap-4 lg:col-span-4"}>
                    <section aria-labelledby="section-1-title" className={'text-right'}>
                        <h2 className="sr-only" id="section-1-title">
                            Section title
                        </h2>
                        <div className="flex items-center border-gray-200 px-4 sm:px-0">
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                <button
                                    onClick={goToPreviousYear}
                                    className="inline-flex items-center pr-4 text-sm font-medium text-blue-700 hover:border-gray-300 hover:text-blue-900"
                                >
                                    <ArrowLongLeftIcon className="mr-2 h-5 w-5 text-lg text-blue-700 hover:text-blue-900"
                                                       aria-hidden="true"/>
                                    Previous
                                </button>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                                >
                                    {yearChosen} - {yearChosen + 1}
                                </button>
                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-start">
                                <a
                                    onClick={goToNextYear}
                                    href="#"
                                    className="inline-flex items-center pl-4 text-sm font-medium text-blue-700 hover:border-gray-300 hover:text-blue-900"
                                >
                                    Next
                                    <ArrowLongRightIcon className="ml-2 h-5 w-5 text-blue-700 hover:text-blue-900" aria-hidden="true"/>
                                </a>
                            </div>
                        </div>
                        <div className="mt-4 overflow-hidden rounded-lg bg-white shadow border border-gray-200">
                            <div className="flex flex-col">
                                <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full">
                                                <thead className="bg-white border-b">
                                                <tr>
                                                    <th colSpan={1} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">

                                                    </th>
                                                    <th colSpan={3} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        Q1
                                                    </th>
                                                    <th colSpan={3} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        Q2
                                                    </th>
                                                    <th colSpan={3} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        Q3
                                                    </th>
                                                    <th colSpan={3} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        Q4
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={1} scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        April
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        May
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        June
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        July
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        August
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        September
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        October
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        November
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        December
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        January
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        February
                                                    </th>
                                                    <th scope="col"
                                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                                        March
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                        Teamwork
                                                    </td>
                                                    {Array(12).fill(1).map((el, index) =>
                                                        <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                            <select
                                                                className={`rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${!checkDisabled(index) ? "border-dashed border-pink-400" : ""}`}
                                                                key={index}
                                                                value={selectedValues[index] || ''}
                                                                disabled={checkDisabled(index)}
                                                                onChange={(event) => handleSelectChange(index, event)}
                                                            >
                                                                <option value={0}> -
                                                                </option>
                                                                {
                                                                    ratings.map((x) => <option value={x.id}
                                                                                               key={x.id}>{x.name}</option>)
                                                                }
                                                            </select>
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                        Innovation
                                                    </td>
                                                    {Array(12).fill(2).map((el, index) =>
                                                        <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                            <select
                                                                className={`rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${!checkDisabled(index) ? "border-dashed border-pink-400" : ""}`}
                                                                key={index + 11}
                                                                value={selectedValues[index + 11] || ''}
                                                                disabled={checkDisabled(index)}
                                                                onChange={(event) => handleSelectChange(index + 11, event)}
                                                            >
                                                                <option value={0}> -
                                                                </option>
                                                                {
                                                                    ratings.map((x) => <option value={x.id}
                                                                                               key={x.id}>{x.name}</option>)
                                                                }
                                                            </select>
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                        Results
                                                    </td>
                                                    {Array(12).fill(1).map((el, index) =>
                                                        <td className="text-sm px-6 py-4 whitespace-nowrap">
                                                            <select
                                                                key={index + 24}
                                                                value={selectedValues[index + 24] || ''}
                                                                disabled={checkDisabled(index)}
                                                                className={`rounded-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${!checkDisabled(index) ? "border-dashed border-pink-400" : ""}`}
                                                                onChange={(event) => handleSelectChange(index + 24, event)}
                                                            >
                                                                <option value={0}> -
                                                                </option>
                                                                {
                                                                    ratings.map((x) =>
                                                                        <option value={x.id}
                                                                                key={x.id}>{x.name}</option>)
                                                                }
                                                            </select>
                                                        </td>
                                                    )}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {user_id && role !== null && role.name === 'Superuser' &&
                            <button
                                disabled={isLoading}
                                onClick={() => setEditMode(!editMode)}
                                type="submit"
                                className="mt-3 mr-3 inline-flex text-right rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {editMode ? 'Lock' : 'Unlock'}
                            </button>
                        }
                        {user_id &&
                            <button
                                disabled={isLoading}
                                onClick={() => handleSubmit()}
                                type="submit"
                                className="mt-3 inline-flex text-right rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >

                                {isLoading &&
                                    <div role="status">
                                        <svg
                                            className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"/>
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"/>
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                {!isLoading &&
                                    <span>Submit</span>
                                }
                            </button>
                        }
                    </section>
                </div>
                <div className="mt-4 w-1/5 bg-white p-7 border border-gray-200 rounded-lg shadow">
                    <div className="row font-bold mb-4">
                        Performance Abbreviation
                    </div>
                    <div className="row">
                        <span className="font-bold">EE</span> - Exceeded Expectation
                    </div>
                    <div className="row">
                        <span className="font-bold">AE</span> - Achieved Expectation
                    </div>
                    <div className="row">
                        <span className="font-bold">EM</span> - Expected More
                    </div>
                </div>
            </div>
        </div>
    </main>);
}
export default MonthlyReview;