import React, {useEffect, useState} from 'react';
import TableDraftsCreateButton from "./TableDraftsCreateButton";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import {Link} from "react-router-dom";
import moment from "moment/moment";

export interface exchangeSubmissions {
    id: string
    name: string
    created_at: string
    exchange: any
    exchange_id: number
    goal_and_experience_exchanges: any
    conversation_exchanges: any
    review_exchanges: any
}

const TableDrafts: React.FC = () => {
    const [exchangeSubmissions, setExchangeSubmissions] = useState<Array<exchangeSubmissions>>([]);

    useEffect(() => {
        retrieveExchangeSubmissions();
    }, []);

    const retrieveExchangeSubmissions = () => {
        Exchange_submissionService.get('?filter[is_draft]=1&filter[exchange_id]=1,2,3&include=exchange,goalAndExperienceExchanges,reviewExchanges,conversationExchanges&sort=-id')
            .then((response: any) => {
                setExchangeSubmissions(response.data.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const checkIfThereIsConversationExchange = exchangeSubmissions.filter(function (value) {
        return value.exchange.name === "Conversation Exchange";
    })

    const checkIfThereIsGoalAndExperienceExchange = exchangeSubmissions.filter(function (value) {
        return value.exchange.name === "Goal And Experience Exchange";
    })

    const checkIfThereIsReviewsExchange = exchangeSubmissions.filter(function (value) {
        return value.exchange.name === "Reviews Exchange";
    })

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Drafts</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    {exchangeSubmissions.length < 3 &&
                        <TableDraftsCreateButton isConversationDisabled={checkIfThereIsConversationExchange.length > 0}
                                                 isGoalAndExperienceDisabled={checkIfThereIsGoalAndExperienceExchange.length > 0}
                                                 isReviewsExchangeDisabled={checkIfThereIsReviewsExchange.length > 0}/>
                    }
                </div>
            </div>
            <div
                className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-40">
                            Exchange Type
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell w-96">
                            Preview
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell w-40">
                            Last Modification
                        </th>

                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 w-40">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {exchangeSubmissions ? exchangeSubmissions.map(d => (
                        <tr key={d.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.exchange.name === 'Goal And Experience Exchange' &&
                                    'Goal and Experience'
                                }

                                {d.exchange.name === 'Conversation Exchange' &&
                                    'Conversation'
                                }

                                {d.exchange.name === 'Reviews Exchange' &&
                                    'Review'
                                }
                            </td>
                            <td className={'max-w-md'}>
                                <div className="m-4 rounded bg-gray-50">
                                    <p className="text-sm text-gray-400 truncate ... overflow-hidden ...">
                                        {d.exchange.name === 'Goal And Experience Exchange' &&
                                            d.goal_and_experience_exchanges[0].goal
                                        }

                                        {d.exchange.name === 'Conversation Exchange' &&
                                            d.conversation_exchanges[0].successes
                                        }

                                        {d.exchange.name === 'Reviews Exchange' &&
                                            d.review_exchanges[0].teamwork
                                        }
                                    </p>
                                </div>
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {moment(d.created_at).format('MMM D, YYYY')}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                {d.exchange_id === 1 &&
                                    <h2>
                                        <Link to={'/conversation-exchange/' + d.conversation_exchanges[0].unique_id}
                                              className={'text-indigo-600 hover:text-indigo-900'}>
                                            Edit
                                        </Link>
                                    </h2>
                                }
                                {d.exchange_id === 2 &&
                                    <h2>
                                        <Link
                                            to={'/goal-and-experience-exchange/' + d.goal_and_experience_exchanges[0].unique_id}
                                            className={'text-indigo-600 hover:text-indigo-900'}>
                                            Edit
                                        </Link>
                                    </h2>
                                }
                                {d.exchange_id === 3 &&
                                    <h2>
                                        <Link to={'/reviews-exchange/' + d.review_exchanges[0].unique_id}
                                              className={'text-indigo-600 hover:text-indigo-900'}>
                                            Edit
                                        </Link>
                                    </h2>
                                }
                            </td>
                        </tr>
                    )) : null}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableDrafts;