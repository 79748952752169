import {useState} from 'react';
import {Listbox} from '@headlessui/react';

export default function ComboboxMultiple(props: any) {
    const [listOfPeople, setListOfPeople] = useState<any[]>([...props.competencyCards]);

    return (
        <>
            <Listbox value={props.selectedPeople} onChange={props.setCompetenciesFromSelect} multiple>
                {({open}) => (
                    <div className="relative">
                        <Listbox.Button
                            className={`block w-full border border-gray-300 rounded-md p-2 mt-2 ${
                                props.selectedPeople.length === 0 ? 'h-10' : ''
                            }`}
                        >
                            {props.selectedPeople.length === 0 ? (
                                <span className="text-gray-500">Select Competencies...</span>
                            ) : (
                                <div className="flex flex-wrap gap-2">
                                    {props.selectedPeople.map((person: any) => (
                                        <span
                                            key={person.id}
                                            className="bg-teal-500 text-white px-2 py-1 rounded-full"
                                        >   {person.title}
                                            <button
                                                type="button"
                                                className="ml-1"
                                                onClick={() => {
                                                    props.setCompetenciesFromSelect((prevSelected: any[]) =>
                                                        prevSelected.filter((p) => p !== person)
                                                    );
                                                }}
                                            >
                                                &#10005;
                      </button>
                    </span>
                                    ))}
                                </div>
                            )}
                        </Listbox.Button>
                        {open && (
                            <Listbox.Options
                                className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
                                {listOfPeople.map((person: any) => (
                                    <Listbox.Option
                                        key={person.id}
                                        value={person}
                                        className={({active, selected}) =>
                                            `cursor-pointer select-none relative p-2 ${
                                                active
                                                    ? 'bg-gray-100'
                                                    : selected
                                                        ? 'bg-teal-500 text-white'
                                                        : 'bg-white'
                                            }`
                                        }
                                    >
                                        {({active, selected}) => (
                                            <>
                        <span
                            className={`${
                                selected ? 'font-semibold' : 'font-normal'
                            } block truncate`}
                        >
                          {person.title}
                        </span>

                                                {selected && (
                                                    <span
                                                        className={`${
                                                            active ? 'text-white' : 'text-teal-500'
                                                        } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                            &#10003;
                          </span>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        )}
                    </div>
                )}
            </Listbox>
        </>
    );
}
