import {Navigate, useLocation} from "react-router-dom";

// @ts-ignore
const Protected = ({children}) => {
    const location = useLocation();

    localStorage.setItem('intended_route', location.pathname);

    if (!localStorage.getItem('token')) {
        return <Navigate to="/login" replace/>;
    }

    if (!localStorage.getItem('user')) {
        return <Navigate to="/login" replace/>;
    }

    const user = JSON.parse(localStorage.getItem('user') ?? "");

    const role = JSON.parse(localStorage.getItem('role') ?? "");

    if (user.is_active !== 1) {
        return <Navigate to="/reset-password" replace/>;
    }

    if (role.name === 'Superuser' && location.pathname === '/') {
        return <Navigate to="/admin" replace/>;
    }

    return children;
};

export default Protected;