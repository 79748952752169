import React, {useState} from "react";
import AuthService from "../Services/auth/auth.service";

type Props = {}

const ChangePassword: React.FC<Props> = () => {
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleLogin = (e: { preventDefault: () => void; }) => {
        setIsSubmitting(true);

        e.preventDefault();

        AuthService.changePassword(password, passwordConfirmation).then(() => function () {

        });
    }

    return (
        <div className="flex h-screen">
            <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src="https://adevait.com/img/assets/logo_adeva.svg"
                            alt="Your Company"
                        />
                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Change your password</h2>
                    </div>

                    <div className="mt-8">
                        <div>
                            <div className="relative mt-6">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-gray-300"/>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6">
                            <form onSubmit={handleLogin} action="#" method="POST" className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="password"
                                            required
                                            onChange={e => setPassword(e.target.value)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password Confirmation
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            type="password"
                                            onChange={e => setPasswordConfirmation(e.target.value)}
                                            required
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        {isSubmitting && <div>Please wait!</div>}
                                        {!isSubmitting && <div>Change Password</div>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                />
            </div>
        </div>
    );
};

export default ChangePassword;
