import React, {useCallback, useEffect, useState} from 'react';
import ManagerDatatableComponent from "../Components/Admin/ManagerDatatableComponent";
import {Link, useParams} from "react-router-dom";
import AuthService from "../Services/auth/auth.service";
import ProfileInformationPanel from "../Components/Home/ProfileInformationPanel";
import UserService from "../Services/user.service";


interface User {
    name: string;
    email: string;
    manager?: {
        name: string;
    };
    position?: {
        name: string;
    };
}

const ManagerConnections = () => {
    let {id} = useParams();
    const [connectedUser, setConnectedUser] = useState<User | null>(null);

    const fetchUser = useCallback(async () => {
        const response = await UserService.get('?include=roleType,manager,position&filter[unique_id]=' + id);
        return response.data.data[0];
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            const user = await fetchUser();
            setConnectedUser(user);
        };

        fetchData();
    }, [fetchUser, id]);

    const role = AuthService.getCurrentRole();

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Admin</h1>
            <div>
                <div className="overflow-hidden rounded-lg bg-white shadow mt-8 mb-8">
                    <ProfileInformationPanel
                        name={connectedUser?.name ?? ''}
                        email={connectedUser?.email ?? ''}
                        managerName={connectedUser?.manager?.name ?? ''}
                        positionName={connectedUser?.position?.name ?? ''}
                        departmentName={''}
                    />
                </div>
            </div>
            <Link to={"/monthly-review/" + id}
                  className="py-2 px-4 text-sm font-medium text-gray-700 focus:text-blue-500 hover:text-blue-500 focus:outline-none border-b-2 focus:border-b-2 focus:border-blue-500 hover:border-b-2 hover:border-blue-500">
                Monthly Ratings
            </Link>
            <Link to={"/card-sort-connection/" + id}
                  className="py-2 px-4 text-sm font-medium text-gray-700 focus:text-blue-500 hover:text-blue-500 focus:outline-none border-b-2 focus:border-b-2 focus:border-blue-500 hover:border-b-2 hover:border-blue-500">
                Sort Cards
            </Link>
            {role !== null && role.name === 'Superuser' &&
                <Link to={"/users/" + id}
                      className="ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit
                </Link>
            }
            {/* Main 3 column grid */}
            <div className="mt-5 grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3">
                                <ManagerDatatableComponent/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>);
}
export default ManagerConnections