import React from 'react';
import TableDrafts from "../Components/Home/TableDrafts";
import TableShared from "../Components/Home/TableShared";
import ProfileInformationPanel from "../Components/Home/ProfileInformationPanel";
import AuthService from "../Services/auth/auth.service";

const Home = () => {
    const person = AuthService.getCurrentUser();

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-6">

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4">
                    <section aria-labelledby="section-2-title">
                        <h2 className="sr-only" id="section-2-title">
                            Section title
                        </h2>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <ProfileInformationPanel name={person.name} email={person.email} managerName={person.manager?.name} positionName={person.position.name} departmentName={''} />
                        </div>
                    </section>
                </div>
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4">
                    <section aria-labelledby="section-1-title">
                        <h2 className="sr-only" id="section-1-title">
                            Section title
                        </h2>
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h1 className="text-xl font-semibold text-gray-900">Growth And
                                                Development</h1>
                                            <p>Using <span className={'font-bold'}>Exchange Journal</span>, you and your manager can prepare for growth and
                                                development conversations - also called <span className={'font-bold'}>exchanges</span>. After you drafted and
                                                shared an exchange, ask your leader to schedule time to discuss it. Your
                                                leader can respond to an exchange with notes or draft and share a new
                                                 exchange with you at any time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h1 className="text-xl font-semibold text-gray-900">Types of Exchanges</h1>
                                            <ul className="list-disc text-md text-black mt-4 ml-4">
                                                <li>Conversation Exchange <br/> <span className={'text-gray-500'}>An exchange about your current actions and results.</span></li>
                                                <li className={'mt-2'}>Goal and Experience Exchange <br/><span className={'text-gray-500'}>A plan to achieve a goal through skill development, actions, experiences, and reflections.</span></li>
                                                <li className={'mt-2'}>Review Exchange <br/><span className={'text-gray-500'}>A reflection on your overall performance before your annual review, using Adeva review criteria. </span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3">
                                <TableDrafts/>
                            </div>
                            <div className="p-3 mt-5 pb-10">
                                <TableShared/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>);
}
export default Home;