import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState, useCallback} from "react";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import {Link, useParams} from "react-router-dom";
import moment from "moment/moment";

interface DataRow {
    exchange_id: number;
    user: any;
    owner: any;
    assignee: any;
    created_at: string;
    date_shared: string;
    updated_at: string;
}

const columns: TableColumn<DataRow>[] = [
    {
        name: 'Exchange Type',
        selector: row => row.exchange_id,
        format: row => formatFormType(row)
    },
    {
        name: 'Shared By',
        selector: row => row.user.name,
    },
    {
        name: 'Date Shared',
        selector: row => moment(row.updated_at).format('ddd, MMM DD, YYYY'),
    },
    {
        name: '',
        selector: row => formatActionType(row),
    },
];

function formatActionType(row: any) {
    if (row.exchange_id === 1) {
        return <h2>
            <Link to={'/conversation-exchange/' + row.conversation_exchanges[0]?.unique_id}
                  className={'text-indigo-600 hover:text-indigo-900'}>
                View
            </Link>
        </h2>
    }

    if (row.exchange_id === 2) {
        return <Link to={'/goal-and-experience-exchange/' + row.goal_and_experience_exchanges[0]?.unique_id}
                     className={'text-indigo-600 hover:text-indigo-900'}>
            View
        </Link>
    }

    if (row.exchange_id === 3 && row.is_reply_submitted) {
        return <div>
            <Link
                to={'/reviews-exchange/' + row.review_exchanges[0]?.unique_id + '/reply/' + row.review_exchanges[1]?.unique_id}
                className={'text-indigo-600 hover:text-indigo-900'}>
                Edit Draft
            </Link>
            <Link to={'/reviews-exchange/' + row.review_exchanges[0]?.unique_id}
                  className={'ml-3 text-indigo-600 hover:text-indigo-900'}>
                View
            </Link>
        </div>
    }

    if (row.exchange_id === 3 && row.is_completed) {
        return <div>
            <Link to={'/reviews-exchange/' + row.review_exchanges[1]?.unique_id}
                  className={'text-indigo-600 hover:text-indigo-900'}>
                View Reply
            </Link>
            <Link to={'/reviews-exchange/' + row.review_exchanges[0]?.unique_id}
                  className={'ml-3 text-indigo-600 hover:text-indigo-900'}>
                View
            </Link>
        </div>
    }

    if (row.exchange_id === 3 && !row.is_completed) {
        return <div>
            <Link to={'/reviews-exchange/' + row.review_exchanges[0]?.unique_id}
                  className={'text-indigo-600 hover:text-indigo-900'}>
                View
            </Link>
        </div>
    }
}

function formatFormType(row: any) {
    if (row.exchange_id === 1) {
        return 'Conversation';
    }

    if (row.exchange_id === 2) {
        return 'Goal and Experience';
    }

    return 'Review';
}

export default function ManagerDatatableComponent() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    let {id} = useParams();

    const fetchExchangeSubmissions = useCallback(async (page: number) => {
        setLoading(true);

        const response = await Exchange_submissionService.getManager(`/${id}?include=user,owner,assignee,conversationExchanges,goalAndExperienceExchanges,reviewExchanges&page=${page}&per_page=${perPage}&delay=1&sort=-date_shared`)

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }, [perPage]);

    const handlePageChange = (page: any) => {
        fetchExchangeSubmissions(page).then();
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        const response = await Exchange_submissionService.getManager(`/${id}?include=user,owner,assignee,conversationExchanges,goalAndExperienceExchanges,reviewExchanges&page=${page}&per_page=${newPerPage}&delay=1&sort=-date_shared`)

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchExchangeSubmissions(1).then();
    }, [fetchExchangeSubmissions]);

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    return (
        <div>
            <DataTable
                title=""
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );

}