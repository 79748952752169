import React from 'react';
import CardSortDatatable from "../../Components/CardSort/CardSortDatatable";
import {useParams} from "react-router-dom";

const CardSortIndex = () => {
    let {id} = useParams();

    return (<main className="mt-12 pb-8">
            <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
                <h1 className="sr-only">Card Sort</h1>
                <div className="mt-5 grid grid-cols-1 items-start gap-3">
                    <div className="grid grid-cols-1 col-span-2">
                        <section aria-labelledby="section-1-title">
                            <div className="overflow-hidden rounded-lg bg-white shadow">
                                <div className="p-3">
                                    <CardSortDatatable user={id}/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default CardSortIndex