import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Conversation_exchangeService from "../../Services/conversation_exchange.service";
import CommentSection from "../../Components/Comments/CommentSection";
import Exchange_submissionService from "../../Services/exchange_submission.service";
import AuthService from "../../Services/auth/auth.service";
import moment from "moment/moment";

interface IFormInputs {
    successes: string
    challenges: string
    contributions: string
    improvements: string,
    exchange_submission: [] | null
}

const schema = yup.object({
    successes: yup.string().required('Successes is required!').max(1500),
    challenges: yup.string().required('Challenges is required!').max(1500),
    contributions: yup.string().required('Contributions is required!').max(1500),
    improvements: yup.string().required('Improvements is required!').max(1500),
}).required();

const ConversationExchangeForm = () => {
    let {id} = useParams();
    const [isDraft, setIsDraft] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [exchangeSubmissionId, setExchangeSubmissionsId] = useState('d12uidb1u2ydhbj1h');
    const [exchangeSubmission, setExchangeSubmission] = useState({
        date_shared: "",
        owner: {
            name: ""
        }
    });

    const navigate = useNavigate();
    const isAddMode = !id;

    const [characterCounts, setCharacterCounts] = useState(
        {
            successes: 0,
            challenges: 0,
            contributions: 0,
            improvements: 0,
        }
    );
    const maxCharacterCount = 1000;

    const handleTextareaChange = (name: string) => (event: { target: { value: string | any[]; }; }) => {
        const currentCount = event.target.value.length;
        setCharacterCounts((prevCounts) => ({
            ...prevCounts,
            [name]: currentCount,
        }));
    };

    function onSubmit(data: any) {
        return isAddMode
            ? submitForm(data, 0)
            : updateForm(id, data, 0);
    }

    function onSubmitDraft(data: any) {
        return isAddMode
            ? submitForm(data, 1)
            : updateForm(id, data, 1);
    }

    function submitForm(data: any, isDraft: number) {
        setIsLoading(true);

        data.is_draft = isDraft;

        return Conversation_exchangeService.create(data)
            .then(() => {
                navigate('/');
            })
            .catch();

    }

    function deleteSubmission(exchangeSubmissionId: string) {
        setIsLoading(true);

        return Exchange_submissionService.destroy(exchangeSubmissionId)
            .then(() => {
                navigate('/');
            })
            .catch();

    }

    function updateForm(id: string | undefined, data: any, isDraft: number) {
        setIsLoading(true);

        data.is_draft = isDraft;

        if (id === undefined) {
            return;
        }

        return Conversation_exchangeService.update(id, data)
            .then(() => {
                navigate('/');
            })
            .catch();
    }

    const {setValue, register, handleSubmit, formState: {errors}} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    const role = AuthService.getCurrentUser();

    useEffect(() => {
        if (!isAddMode) {
            Conversation_exchangeService.get('?include=exchangeSubmission.owner&filter[unique_id]=' + id).then(conversationExchange => {
                const fields = ['successes', 'challenges', 'contributions', 'improvements', 'exchange_submission'];

                // @ts-ignore
                fields.forEach(field => setValue(field, conversationExchange.data.data[0][field]));

                setIsDraft(conversationExchange.data.data[0].exchange_submission.is_draft)

                setExchangeSubmissionsId(conversationExchange.data.data[0].exchange_submission.id);
                setExchangeSubmission(conversationExchange.data.data[0].exchange_submission);
            });
        }
    }, [id, isAddMode, setValue]);

    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className={"grid grid-cols-1 lg:grid-cols-6 lg:gap-8"}>
                <div
                    className={"grid grid-cols-1 gap-4 lg:col-span-4 " + (!isAddMode && !isDraft ? "lg:col-start-1" : 'lg:col-start-2')}>
                    <section aria-labelledby="section-1-title">
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 pt-6">
                                <div className="px-4 sm:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <form
                                                className="space-y-8 divide-y divide-gray-200">
                                                <div className="space-y-8 divide-y divide-gray-200">
                                                    <div>
                                                        <div>
                                                            <h3 className="text-2xl font-medium leading-6">Conversation
                                                                Exchange</h3>
                                                            <p className="mt-1 text-sm text-black pt-2">
                                                                At Adeva, we strive to set and accomplish clear goals.
                                                                Your daily actions in decisions,
                                                                approaches,
                                                                and ideas significantly affect the results of your work
                                                                and your team. Discuss these actions
                                                                with your
                                                                leader so you can understand how to perform in Adeva’s
                                                                environment.
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">My
                                                                Actions</h3>
                                                            <p className="text-sm mt-1 text-gray-600">
                                                                Include your ideas, feelings, observations and lessons
                                                                learned.
                                                            </p>
                                                            <p className="text-sm text-black mt-3">
                                                                Write examples of any recent successes and challenges in
                                                                your role. Include:
                                                            </p>
                                                            <ul className="list-disc text-sm text-black mt-4 ml-4">
                                                                <li>Description of successes or challenges</li>
                                                                <li className={'mt-2'}>How you recognised successes or
                                                                    challenges
                                                                </li>
                                                                <li className={'mt-2'}>How the success or challenge
                                                                    affects your ability to complete your
                                                                    work
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div
                                                            className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Successes
                                                                </label>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("successes")}
                                                                        disabled={isDraft === 0}
                                                                        name="successes"
                                                                        rows={6}
                                                                        className={`block w-full rounded ${errors.successes ? 'border-red-500' : 'border-gray-300'} ${characterCounts.successes > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        onChange={handleTextareaChange('successes')}
                                                                        placeholder={'I continue to be effective because since our last monthly meeting I have consistently closed a high number of cases and done so within the timescales required by the department.  \n' +
                                                                            'My work has been of high quality and many of my cases have been complex. I have received positive feedback from my colleagues and my Manager. \n' +
                                                                            'Some key examples of this include:'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.successes?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.successes > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.successes}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Challenges
                                                                </label>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("challenges")}
                                                                        disabled={isDraft === 0}
                                                                        name="challenges"
                                                                        rows={6}
                                                                        className={`block w-full rounded ${errors.challenges ? 'border-red-500' : 'border-gray-300'} ${characterCounts.challenges > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        onChange={handleTextareaChange('challenges')}
                                                                        placeholder={'I feel I sometimes spend too long trying to informally resolve a case and need to gain the confidence to move to a decision notice more swiftly. \n' +
                                                                            'I would welcome a discussion about which peers are adept at this so that I may gain some techniques from them.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.challenges?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.challenges > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.challenges}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h3 className="text-xl font-medium leading-6 mt-8">My
                                                                Results</h3>
                                                            <p className="text-sm mt-1 text-gray-600">
                                                                Results can include any relevant metrics used to measure
                                                                performance.
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Contributions
                                                                </label>
                                                                <p className="text-sm mt-1 text-gray-600">
                                                                    Summarise contributions you made to your team and
                                                                    how you accomplished them. Describe the ways your
                                                                    contributions improved your team’s performance and
                                                                    any metrics that illustrate your results.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("contributions")}
                                                                        disabled={isDraft === 0}
                                                                        name="contributions"
                                                                        rows={6}
                                                                        className={`block w-full rounded ${errors.contributions ? 'border-red-500' : 'border-gray-300'} ${characterCounts.contributions > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        onChange={handleTextareaChange('contributions')}
                                                                        placeholder={'I ensured that 90% of my cases were closed within six weeks from the date of receipt in line with our service standards.\n' +
                                                                            'I didn’t have any open cases over twelve weeks old of the date receipt. I dealt with secondary correspondence within 10 working days of receipt.\n' +
                                                                            'I also wish to point to the training and mentoring I have provided over the past couple of months which has really benefited two peers and increased the performance of the team overall.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.contributions?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.contributions > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.contributions}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-6 mt-4">
                                                                <label htmlFor="about"
                                                                       className="block text-xl font-medium leading-6">
                                                                    Improvements
                                                                </label>
                                                                <p className="text-sm mt-1 text-gray-600">
                                                                    List or describe how you could improve your team’s
                                                                    results.
                                                                </p>
                                                                <div className="mt-4">
                                                                    <textarea
                                                                        {...register("improvements")}
                                                                        name="improvements"
                                                                        disabled={isDraft === 0}
                                                                        rows={6}
                                                                        className={`block w-full rounded ${errors.improvements ? 'border-red-500' : 'border-gray-300'} ${characterCounts.improvements > maxCharacterCount ? 'border-red-500' : 'border-gray-300'} shadow-sm sm:text-sm`}
                                                                        onChange={handleTextareaChange('improvements')}
                                                                        placeholder={'Given my level of knowledge of our processes and procedures, I feel I can contribute more widely in my role by mentoring and training new peers, and I would like to explore how I might go about doing this.\n' +
                                                                            'I would also like to contribute more to the strategy meeting and implementation stage, and would like to explore any opportunities for this.\n' +
                                                                            'It would be useful if we put the departmental stats into greater context when discussing them with the team, so that I am clearer about the scale of the challenge we face and then be able to identify how we may achieve our goals.'}
                                                                    />
                                                                    <div className="flex items-center justify-between">
                                                                        <p className="text-red-500 text-sm mt-1">{errors.improvements?.message}</p>
                                                                        <p className="mt-1 flex justify-end text-xs">
                                                                            <span className={`${characterCounts.improvements > maxCharacterCount ? 'text-red-500' : ''}`}>{characterCounts.improvements}</span>
                                                                            <span>/{maxCharacterCount}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {role.manager_id != null &&
                                                    <div className="pt-5">
                                                        <div className="flex justify-end">
                                                            <Link to={"-1"}
                                                                  onClick={() => navigate(-1)}
                                                                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                            >
                                                                Cancel
                                                            </Link>
                                                            {isDraft === 1 && id &&
                                                                <button
                                                                    disabled={isLoading}
                                                                    onClick={() => deleteSubmission(exchangeSubmissionId)}
                                                                    type="submit"
                                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                >
                                                                    {isLoading &&
                                                                        <div role="status">
                                                                            <svg
                                                                                className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                                viewBox="0 0 100 101" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                    fill="currentColor"/>
                                                                                <path
                                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                    fill="currentFill"/>
                                                                            </svg>
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }

                                                                    {!isLoading &&
                                                                        <span>Delete Draft</span>
                                                                    }
                                                                </button>
                                                            }

                                                            {isDraft === 1 &&
                                                                <button
                                                                    disabled={isLoading}
                                                                    onClick={handleSubmit(onSubmitDraft)}
                                                                    type="submit"
                                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600"
                                                                >
                                                                    {isLoading &&
                                                                        <div role="status">
                                                                            <svg
                                                                                className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                                viewBox="0 0 100 101" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                    fill="currentColor"/>
                                                                                <path
                                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                    fill="currentFill"/>
                                                                            </svg>
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }

                                                                    {!isLoading &&
                                                                        <span>Save as Draft</span>
                                                                    }
                                                                </button>
                                                            }
                                                            {isDraft === 1 &&
                                                                <button
                                                                    disabled={isLoading}
                                                                    onClick={handleSubmit(onSubmit)}
                                                                    type="submit"
                                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700"
                                                                >
                                                                    {isLoading &&
                                                                        <div role="status">
                                                                            <svg
                                                                                className="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                                viewBox="0 0 100 101" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                    fill="currentColor"/>
                                                                                <path
                                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                    fill="currentFill"/>
                                                                            </svg>
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                    {!isLoading &&
                                                                        <span>Share</span>
                                                                    }
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {!isAddMode && !isDraft &&
                    <div className="grid grid-cols-1 col-span-2 gap-4">
                        <div>
                            <ul
                                className=" grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                <li key={'filip.stojkovski13@gmail.com'}
                                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                                    <div
                                        className="flex w-full items-center justify-between space-x-6 p-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="truncate text-sm font-medium text-gray-900">Submitted by: {exchangeSubmission.owner?.name}</h3>
                                            </div>
                                            <p className="mt-1 truncate text-sm text-gray-500">Date
                                                Shared: {moment(exchangeSubmission.date_shared).format('dddd, MMMM D, YYYY')}</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">Time
                                                Shared: {moment(exchangeSubmission.date_shared).format('hh:mm A')}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <CommentSection owner={exchangeSubmission.owner} submissionId={exchangeSubmissionId}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    </main>);
}

export default ConversationExchangeForm;