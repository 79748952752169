import React from 'react';
import UsersNotActiveDatatableComponent from "../../Components/Admin/UsersNotActiveDatatableComponent";

const AdminArchived = () => {
    return (<main className="mt-12 pb-8">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:max-w-full lg:px-8">
            <h1 className="sr-only">Admin Archived</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3">
                                <UsersNotActiveDatatableComponent/>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>);
}
export default AdminArchived;