import React from "react";

interface ProfileInformationPanelProps {
    name: string;
    email: string;
    managerName?: string;
    positionName?: string;
    departmentName?: string;
}

const ProfileInformationPanel: React.FC<ProfileInformationPanelProps> = ({
 name,
 email,
 managerName,
 positionName,
 departmentName
}) => {
    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1">
                <ul className="grid gap-6">
                    <li key={email} className="rounded-lg bg-white">
                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                    <h3 className="truncate text-lg font-medium text-gray-900">
                                        {name}
                                    </h3>
                                </div>
                                <p className="mt-1 truncate text-md text-gray-500">
                                    Connection Manager: {managerName}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="col-span-1">
                <div className="block justify-start p-6">
                    {/* Assuming person.position is defined in the props */}
                    <p className="mt-1 truncate text-md text-gray-500">
                        Position: {positionName}
                    </p>
                    {/*<p className="mt-1 truncate text-md text-gray-500">Department: {departmentName}</p>*/}
                </div>
            </div>
        </div>
    );
};

export default ProfileInformationPanel;