import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState} from "react";
import UserService from "../../Services/user.service";
import {Link} from "react-router-dom";

interface DataRow {
    id: number;
    role: string;
    email: string;
    name: string;
    manager: any;
    managees: any;
    unique_id: string;
}

const columns: TableColumn<DataRow>[] = [
    {
        name: 'Employee Name',
        selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    },
    {
        name: 'Role',
        selector: row => row.role,
    },
    {
        name: 'Connection Manager',
        selector: row => row.manager ? row.manager.name : "No Connection Manager",
    },
    {
        name: '',
        selector: row => row.unique_id,
        format: row =>
            <h2>
                <Link to={'/manager/connection/' + row.unique_id} className={'text-indigo-600 hover:text-indigo-900'}>
                    Edit
                </Link>
            </h2>
    },
];
export default function UsersDatatableComponent() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    function runSearch() {
        fetchUsers(1, searchTerm);
    }

    const fetchUsers = async (page: number, searchTerm: string = '') => {
        setLoading(true);

        let searchString = ``;

        if (searchTerm !== "") {
            searchString = `&filter[name]=${searchTerm}&filter[has_managees]=${searchTerm}`;
        }

        const response = await UserService.get(`?sort=name&include=manager,managees&filter[is_active]=1&filter[is_manager_role]=Manager${searchString}&page=${page}&per_page=${perPage}&delay=1`)

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = (page: any) => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setLoading(true);

        const response = await UserService.get(`?sort=name&include=manager,managees&filter[has_managees]=filip&filter[is_manager_role]=Manager&filter[is_active]=1&page=${page}&per_page=${newPerPage}&delay=1`)

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1);
    }, []);

    // @ts-ignore
    const ExpandedComponent = ({data}) => <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-8 sm:space-y-12">
                <ul
                    role="list"
                    className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6"
                >
                    {data.managees.map((person: any) => (
                        <li key={person.name}>
                            <div className="space-y-4">
                                <Link to={'/manager/connection/' + person.unique_id}>
                                    <img className="mx-auto h-20 w-20 rounded-full lg:h-24 lg:w-24"
                                         src={'https://cdn-icons-png.flaticon.com/512/147/147144.png'}
                                         alt=""/>
                                </Link>
                                <div className="space-y-2">
                                    <div className="text-xs font-medium lg:text-sm">
                                        <Link to={'/manager/connection/' + person.unique_id}>
                                            <h3>{person.name}</h3>
                                        </Link>
                                        <Link to={'/manager/connection/' + person.unique_id}>
                                            <p className="text-indigo-600">{person.role}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>;

    const customStyles = {
        headRow: {
            style: {
                fontWeight: "600",
                fontSize: '0.875rem;',
            },
        },
    };

    return (
        <div>
            <div className={'grid grid-cols-3 w-full'}>
                <div>
                    <Link to={'/users/create'}>
                        <button
                            className={"ml-3 inline-flex justify-center rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                            Create User
                        </button>
                    </Link>
                    <Link to={'/admin/archived'}>
                        <button
                            className={"ml-3 inline-flex justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                            Archived Users
                        </button>
                    </Link>
                    <Link to={'/admin/non-assigned'}>
                        <button
                            className={"ml-3 inline-flex justify-center rounded-md border border-transparent bg-gray-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"}>
                            Non Assigned Users
                        </button>
                    </Link>
                </div>
                <div></div>
                <div className={'grid grid-cols-3'}>
                    <div></div>
                    <div className="relative rounded-md shadow-sm w-48">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input onChange={e => setSearchTerm(e.target.value)}
                               type="search" name="search" id="search"
                               className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                               placeholder="Search"/>
                    </div>
                    <div>
                        <button onClick={e => runSearch()}
                                className={'ml-2    rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'}>
                            Search
                        </button>
                    </div>
                </div>
            </div>

            <DataTable
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                title="Connection Managers"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
            />
        </div>
    );

}