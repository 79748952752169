import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import UserService from "../../Services/user.service";

type ChildComponentProps = {
    setAssigneeFromInput: (assignee: number) => void;
};

const ComboboxSearchable: React.FC<ChildComponentProps> = ({setAssigneeFromInput}) => {

    const [query, setQuery] = useState('')

    useEffect(() => {
        UserService.get(`?filter[name]=` + query).then(result => {
            setUsers(result.data.data);
        });
    }, [query]);

    const [users, setUsers] = useState([])

    const setAssigneeFromInputLocal = (assignee: any) => {
        setAssigneeFromInput(assignee.id);
    };

    return (
        <Combobox onChange={setAssigneeFromInputLocal}>
            <div className="relative">
                <div
                    className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        displayValue={(person: any) => person?.name ?? ""}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options
                        className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {(users.length === 0 && true) && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                Nothing found.
                            </div>
                        ) : (
                            users.map((person: any) => (
                                <Combobox.Option
                                    key={person.id}
                                    className={({active}) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-slate-200 text-gray-900 hover:cursor-pointer' : 'text-gray-900'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({selected, active}) => (
                                        <>
                                            <span
                                                className={`block truncate ${
                                                    selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                              {person?.name ?? ""}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active ? 'text-white' : 'text-teal-600'
                                                    }`}
                                                >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                              </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}

export default ComboboxSearchable;
