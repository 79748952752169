import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Droppable(props: any) {
    const {setNodeRef} = useDroppable({
        id: props.id,
        data: {
            accepts: ['type1', 'type2'],
        },
    });

    return (
        <div ref={setNodeRef}>
            {props.children}
        </div>);
}